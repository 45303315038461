import React from "react";
import { Modal, Form, Input, Slider } from "antd";
import { IBookingStatus, ReviewList } from "../../../src/Constant";
import { AddBookingStatus, AddReviewData } from "../../../src/Api";
import { toast } from "react-toastify";

const AddReviewModal = ({
  selectedMessageId,
  visible,
  setIsPaymentModelVisible,
  guestId,
  guideId,
  fetchData
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      const reviewsArray: any = ReviewList.map((reviewItem) => ({
        reviewItem,
        rating: values[reviewItem],
      }));
      const reviewsComment = values.overallComment;

      const data = {
        reviewsComment,
        reviewsArray: reviewsArray,
        bookingId: selectedMessageId,
        guestId: guestId,
        guideId: guideId,
      };

      const responce = await AddReviewData(data);

      if (responce?.isDataAdded) {
        toast.success(responce?.msg);
        await AddBookingStatus({
          status: IBookingStatus.reviewadded,
          selectedMessageId: selectedMessageId,
        });
        fetchData()
      } else {
        toast.error(responce?.msg);
      }

      form.resetFields();
      setIsPaymentModelVisible(false);
    });
  };

  return (
    <Modal
      title="Add Review for your guide"
      visible={visible}
      onCancel={() => setIsPaymentModelVisible(false)}
      onOk={handleSubmit}
      okText="Submit"
    >
      <Form form={form} layout="vertical">
        {ReviewList.map((reviewItem, index) => (
          <Form.Item
            key={index}
            name={reviewItem}
            label={reviewItem}
            className="my-0 py-0"
            rules={[{ required: true, message: `Please rate ${reviewItem}` }]}
          >
            <Slider min={0} max={5} step={0.1} />
          </Form.Item>
        ))}
        <Form.Item
          name="overallComment"
          className="mt-3"
          rules={[{ required: true, message: `Please add an overall comment` }]}
        >
          <Input.TextArea rows={4} placeholder="Write your comments here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddReviewModal;

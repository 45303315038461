import { Card } from "antd";
import React from "react";

export const PaymentHistory = ({ paymentHistory }) => {
  return (
    <>
      {paymentHistory?.length != 0 && (
        <Card className="p-4 shadow-lg">
          <div className="my-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <h2 className="text-lg font-bold mb-4">Payment History</h2>
            <div className="space-y-4">
              {paymentHistory.map((payment) => (
                <div
                  key={payment.id}
                  className="flex justify-between items-center bg-white p-4 rounded-lg shadow"
                >
                  <div className="text-left">
                    <h3 className="font-semibold">{payment.type}</h3>
                    <p className="text-sm text-gray-500">{payment.datetime}</p>
                    <p className="text-sm text-gray-500">
                      Payment ID: {payment.paymentid}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold text-lg">
                      Amount: ${payment.amount}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

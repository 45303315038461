import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../../src/Constant";

export const AddGuideBioData = async (formData: any) => {
  try {
    const formDataSet = new FormData();
    // Append other form data
    formDataSet.append(
      "inputs",
      JSON.stringify({
        ...formData,
      })
    );

    // Append the profile image if it exists
    if (formData?.profileImage?.file) {
      formDataSet.append(
        "profileImageArray[]",
        formData.profileImage.file.originFileObj
      );
    }

    // Append the nicImage image if it exists
    if (formData?.nicImage?.file) {
      formDataSet.append(
        "nicImageArray[]",
        formData.nicImage.file.originFileObj
      );
    }

    // Append the guideIdImage image if it exists
    if (formData?.guideIdImage?.file) {
      formDataSet.append(
        "guideIdImageArray[]",
        formData.guideIdImage.file.originFileObj
      );
    }

    // Append the gallery image if it exists
    if (formData?.galleryImages && Array.isArray(formData.galleryImages)) {
      formData.galleryImages.forEach((image) => {
        if (image.originFileObj) {
          formDataSet.append("guideGalleryImageArray[]", image.originFileObj);
        }
      });
    }

    // Append the vehicle images if they exist
    if (formData?.vehicles && formData?.vehicles.length > 0) {
      formData.vehicles.forEach((vehicle, index) => {
        if (vehicle.images && vehicle.images.length > 0) {
          vehicle.images.forEach((image) => {
            if (image.originFileObj) {
              formDataSet.append(
                `vehicleImageArray${index + 1}[]`,
                image.originFileObj
              );
            }
          });
        }
      });
    }

    const response = await axios.post(
      `${SERVER_API}/Guide/add-guide-bio-data.php`,
      formDataSet,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);

    toast.error("Something went wrong");
  }
};

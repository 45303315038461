import React from "react";
import { useMediaQuery } from "@mui/material";
import { Card, Typography } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { YT_TUTORIAL } from "../../../../src/Constant";

const { Title, Paragraph } = Typography;

export const HowToReg: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <section className="bg-white py-8 px-4 md:py-12 md:px-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div
            className="flex-1 md:w-1/2"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <Title level={1}>How can Trained Guide help you</Title>
            <Card
              className="shadow-lg rounded-lg flex-wrap"
              bordered={false}
              bodyStyle={{ padding: "16px" }}
            >
              <div className="space-y-4">
                <div>
                  <Title level={5} className="text-gray-800">
                    1. Help create itinerary plan
                  </Title>
                  <Paragraph className="text-gray-600">
                    If an itinerary is planned, start accordingly or help you
                    prepare an itinerary plan to suit you.
                  </Paragraph>
                </div>

                <div>
                  <Title level={5} className="text-gray-800">
                    2. Pick up & Help with your luggage
                  </Title>
                  <Paragraph className="text-gray-600">
                    Our friendly trained guide will pick you up from the airport
                    or your place and handle your bags for you, keeping them
                    safe.
                  </Paragraph>
                </div>

                <div>
                  <Title level={5} className="text-gray-800">
                    3. Follow itinerary plan
                  </Title>
                  <Paragraph className="text-gray-600">
                    Our trained guide will bring you to fantastic locations and
                    teach you about the present and past history of these
                    places.
                  </Paragraph>
                </div>

                <div>
                  <Title level={5} className="text-gray-800">
                    4. Back to Home
                  </Title>
                  <Paragraph className="text-gray-600">
                    After completing the itinerary plans, our friendly trained
                    guide will drop you off at the airport or your location and
                    handle your bags for you.
                  </Paragraph>
                </div>
              </div>
            </Card>
          </div>

          <div
            className="flex-1 md:w-1/2 mt-8 md:mt-0"
            data-aos="fade-down"
            data-aos-delay="400"
          >
            <iframe
              width={isMobile ? "100%" : 600}
              className="w-full shadow-lg border-0 rounded-lg"
              height={isMobile ? "auto" : 400}
              src={YT_TUTORIAL}
              allowFullScreen
              title="See How It Work"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

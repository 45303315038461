import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { IMAGE_URL } from "../../Constant";
import { EyeOutlined } from "@ant-design/icons";

export const ImageGallery = ({ imagesArray }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(
    Array(imagesArray.length).fill(true)
  );

  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  const handleImageLoad = (index) => {
    const newLoadingStatus = [...loadingStatus];
    newLoadingStatus[index] = false;
    setLoadingStatus(newLoadingStatus);
  };

  return (
    <div className="p-5 bg-gray-100">
      <div className="text-center mb-12">
        <p className="text-lg font-semibold text-gray-700">Our Memories.</p>
        <h2 className="text-3xl font-bold text-gray-900">
          Trained Guide <span className="text-blue-600">Gallery</span>
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 h-auto">
        {imagesArray?.map((image, index) => (
          <div
            key={image?.id}
            className="relative overflow-hidden cursor-pointer group"
            onClick={() => showModal(image)}
          >
            <img
              src={`${IMAGE_URL}/upload/${image?.src}`}
              alt={image?.alt}
              onLoad={() => handleImageLoad(index)}
              className="w-full h-48 object-cover rounded-lg transition-transform transform hover:scale-105"
            />

            {loadingStatus[index] && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                <Spin size="small" />
              </div>
            )}

            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <EyeOutlined className="text-white text-xs" />
              <span className="text-white text-xs mt-1">Preview</span>
            </div>
          </div>
        ))}
      </div>

      {currentImage && (
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          width={"80vh"}
          height={"80vh"}
          centered
        >
          <img
            src={`${IMAGE_URL}/upload/${currentImage?.src}`}
            alt={currentImage?.alt}
            className="w-full h-auto p-0 m-0"
          />
        </Modal>
      )}
    </div>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { WEB_LINK } from "./Constant";

ReactDOM.render(
  <>
    <React.StrictMode>
      <KindeProvider
        //@ts-ignore
        clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
        //@ts-ignore
        domain={process.env.REACT_APP_KINDE_DOMAIN_NAME}
        redirectUri={`${WEB_LINK}/loging-success`}
        logoutUri={`${WEB_LINK}`}
      >
        <App />
      </KindeProvider>
    </React.StrictMode>
  </>,
  document.getElementById("root")
);
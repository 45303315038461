import React from "react";
import "./globals.css";
import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  AdminNavBar,
  Filters,
  NavigationBar,
  ScrollUpButton,
  TopNav,
} from "./components";
import {
  AboutUsPage,
  AuthPage,
  ContactUsPage,
  GalleryPage,
  HomePage,
  Loginsuccess,
  NotFoundPage,
  SearchGuidePage,
  SingleView,
  WebTermsAndCondition,
} from "./Page";
import { Footer } from "./components/footer/Footer";
import { ToastContainer } from "react-toastify";
import {
  AUTH_PAGE,
  GALLERYPAGEURL,
  DASHBOARD,
  HOMEPAGEURL,
  LOGING_SUCCESS,
  SEARCHGUIDE,
  GUIDE_TERMS_AND_CONDITION,
  GUIDEPUBLICPROFILE,
  PAGENOTFOUND,
  ABOUTAGEURL,
  CONTACTPAGEURL,
  TERMSPAGEURL,
  ADMIN_GUIDE_MANAGE,
} from "./Constant";
import { GuideDashBoard, GuideTermsAndCond } from "./Page/Guide-profile";
import { GuestDashBoard } from "./Page/Guest-profile";
import { AdminDashBoard, GuideManage } from "./Page/Admin";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* Web Page Navigation */}
          <Route
            path="/*"
            element={
              <>
                <NavigationBar />
                <Routes>
                  <Route path={HOMEPAGEURL} element={<HomePage />} />
                  <Route path={GALLERYPAGEURL} element={<GalleryPage />} />
                  <Route
                    path={`${SEARCHGUIDE}/:key/:country/:lang/:rate`}
                    element={<SearchGuidePage />}
                  />
                  <Route
                    path={`${GUIDEPUBLICPROFILE}/:userid`}
                    element={<SingleView />}
                  />
                  <Route path={AUTH_PAGE} element={<AuthPage />} />
                  <Route path={LOGING_SUCCESS} element={<Loginsuccess />} />
                  <Route path={ABOUTAGEURL} element={<AboutUsPage />} />
                  <Route path={CONTACTPAGEURL} element={<ContactUsPage />} />
                  <Route
                    path={TERMSPAGEURL}
                    element={<WebTermsAndCondition />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path={PAGENOTFOUND} element={<NotFoundPage />} />
                </Routes>
                <ScrollUpButton />
                <Filters />
                <ToastContainer />
                <Footer />
              </>
            }
          />
          {/* Guest Navigation */}
          <Route
            path="/guest/*"
            element={
              <>
                <TopNav />
                <Routes>
                  <Route
                    path={`${DASHBOARD}/:userid`}
                    element={<GuestDashBoard />}
                  />

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <ScrollUpButton />
                <ToastContainer />
                <Footer />
              </>
            }
          />
          {/* Guide Navigation */}
          <Route
            path="/guide/*"
            element={
              <>
                <TopNav />
                <Routes>
                  <Route
                    path={`${DASHBOARD}/:userid`}
                    element={<GuideDashBoard />}
                  />
                  <Route
                    path={`${DASHBOARD}/:userid/${GUIDE_TERMS_AND_CONDITION}`}
                    element={<GuideTermsAndCond />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <ScrollUpButton />
                <ToastContainer />
                <Footer />
              </>
            }
          />
          {/* Admin Navigation */}
          <Route
            path="/trained-guide-admin/*"
            element={
              <>
                <AdminNavBar />
                <Routes>
                  <Route
                    path={`${DASHBOARD}/:userid`}
                    element={<AdminDashBoard/>}
                  />
                  <Route
                    path={`${ADMIN_GUIDE_MANAGE}/:userid`}
                    element={<GuideManage/>}
                  />
                </Routes>
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

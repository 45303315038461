import React from "react";
import { Card, Avatar, Typography } from "antd";
import { IMAGE_URL, IReviewCard } from "../../../src/Constant";
import { useGenerateStars } from "../../../src/Context";
import { TruncatedTextWithTooltip } from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";
const { Text } = Typography;

interface ReviewCardProps extends IReviewCard {}

export const ReviewCard: React.FC<ReviewCardProps> = ({
  name,
  review,
  avatar,
  rate,
}: any) => {
  const generateStars = useGenerateStars();

  return (
    <Card className="shadow-md rounded-lg bg-white my-2">
      <div className="flex items-center mb-4">
        <Avatar src={`${IMAGE_URL}/upload/${avatar}`} size={64} className="mr-4" />
        <div className="flex flex-col">
          <Text className="font-semibold text-lg">{name}</Text>
          <div>{generateStars(rate)} <span className="font-bold">({rate})</span></div>
        </div>
      </div>
      <Text className="text-gray-700">{TruncatedTextWithTooltip(review,100)}</Text>
    </Card>
  );
};
import React, { useEffect } from "react";
import { Spin } from "antd";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthCheck } from "../../../src/Api";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD,
  HOMEPAGEURL,
  UserRoles,
  UserRolesEnum,
} from "../../../src/Constant";
import { toast } from "react-toastify";
import { addUserRoleToSession } from "../../../src/Hook/useAuth";

export const Loginsuccess = () => {
  const { user, isAuthenticated, isLoading, logout } = useKindeAuth();
  const user_role = sessionStorage.getItem("user_role");
  const navigate = useNavigate();

  const sendAuthData = async () => {
    try {
      const data = {
        user_role: user_role,
        user: user,
      };
      const response: any = await AuthCheck(data);

      if (response?.isDataSet && response?.isAuthDone) {
        addUserRoleToSession(response?.validate_user_role);
        sessionStorage.setItem("kd_id", response?.userData?.kd_id);

        if (response?.validate_user_role !== UserRolesEnum.user) {
          navigate(
            `../${response?.validate_user_role}/${DASHBOARD}/${response?.userData?.kd_id}`
          );
        } else {
          toast.warning("Something went wrong!");
          logout();
          navigate(HOMEPAGEURL);
        }
      } else {
        toast.warning(
          "Something went wrong. Please check the data and try again."
        );
        logout();
        navigate(HOMEPAGEURL);
      }
    } catch (error) {
      console.error("Error sending auth data:", error);
    }
  };

  useEffect(() => {
    let timeoutId: any;

    if (isAuthenticated && !isLoading && user) {
      timeoutId = setTimeout(() => {
        sendAuthData();
      }, 15000);
    } else {
      timeoutId = setTimeout(() => {
        navigate(HOMEPAGEURL);
      }, 15000);
    }
  }, [isAuthenticated, isLoading, user]);

  return (
    <div className="flex flex-col items-center justify-center h-[60vh] bg-gray-300">
      <Spin
        fullscreen
        size="default"
        tip={
          "You have successfully logged in. Please wait while we redirect you to your dashboard."
        }
      ></Spin>
    </div>
  );
};

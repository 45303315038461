import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Button, Input, Select, Radio, Collapse, Drawer } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { scrollToTop } from "../../../src/lib";
import { toast } from "react-toastify";

const { Option } = Select;

export const Filters: React.FC<any> = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [keyword, setKeyword] = useState("");
  const [guestCount, setGuestCount] = useState("1");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [country, setCountry] = useState("1");
  const [language, setLanguage] = useState("english");
  const [sortPrice, setSortPrice] = useState("h2l");
  const [filterdData, setFilterdData] = useState<any>([]);
  const [isSort, setIsSort] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const checkAvailability = () => {
    const data = {
      keyword,
      guestCount,
      checkIn,
      checkOut,
      country,
      setLanguage,
      sortPrice,
    };

    if (data.checkIn && data.checkOut && data.guestCount) {
      toast.success("Request send");
      setIsSort(true);
      setFilterdData([
        {
          id: "109465902996742298579",
          name: "sahan filter test",
          rate: 4,
          type: "national",
          note: "",
          price: 10,
          isVehicleAvailable: true,
          image: "maxwin_gallery_img_4.webp",
        },
      ]);
    } else {
      toast.warn("Fill all required fields");
    }
  };

  return (
    <div>
      <Button
        type="primary"
        size="large"
        onClick={showDrawer}
        icon={<FilterOutlined className="font-extrabold" />}
        className="filter-button py-10"
      ></Button>

      <Drawer
        title="Filter"
        height={550}
        width={500}
        placement={isMobile ? "bottom" : "right"}
        closable={true}
        onClose={onClose}
        className="z-[999]"
        open={open}
        key={isMobile ? "bottom" : "right"}
        footer={
          <>
            <Button
              type="primary"
              className="mt-0 mb-4 float-end"
              onClick={() => {
                checkAvailability();
                scrollToTop();
              }}
            >
              Check Availability
            </Button>
          </>
        }
      >
        <div className="p-4">
          <div className="mb-4">
            <label className="block mb-2">Adults Guest Count</label>
            <Input
              type="number"
              value={guestCount}
              onChange={(e) => setGuestCount(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Country</label>
            <Select
              value={country}
              onChange={(value) => setCountry(value)}
              placeholder="Select Country"
              className="w-full"
            >
              <Option value="Sri Lanka">Sri Lanka</Option>
            </Select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Language</label>
            <Input
              type="text"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Price Rate ($):</label>
            <Radio.Group
              value={sortPrice}
              onChange={(e) => setSortPrice(e.target.value)}
              className="w-full"
            >
              <Radio value="l2h">Low to High</Radio>
              <Radio value="h2l">High to Low</Radio>
            </Radio.Group>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

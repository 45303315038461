import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Spin, Empty } from "antd";
import { Guidecard } from "../../../src/components";
import { GetAllGuideData } from "../../../src/Api";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const SearchGuidePage = () => {
  const [guides, setGuides] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1,
  });
  const { key, country, lang, rate } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const filterData = {
        key,
        country,
        lang,
        rate,
      };
      const data = await GetAllGuideData(9, page, filterData);
      setGuides((prevGuides) => [...prevGuides, ...data?.GuideData]);
      setHasMore(data?.GuideData?.length > 0);
    } catch (err) {
      toast.error("Somthing went wrong.Please try againg later");
    } finally {
      setIsLoading(false);
    }
  }, [page, key, country, lang, rate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (inView && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <Spin />
          <p className="my-0 py-0 ms-5">
            Data is loading. please wait a moment
          </p>
        </div>
      ) : (
        <>
          {guides.length == 0 ? (
            <div className="flex justify-center items-center h-[80vh]">
              <Empty />
            </div>
          ) : (
            <div className="p-4">
              <Row gutter={16} justify="center">
                {guides.map((guide, index) => (
                  <Col
                    key={index}
                    xs={20}
                    sm={12}
                    md={12}
                    lg={8}
                    className="mb-4"
                  >
                    <Guidecard
                      image={guide.image}
                      name={guide.name}
                      showerDescription={guide.showerDescription}
                      rate={guide.rate}
                      type={guide.type}
                      isVehicleAvailable={guide.isVehicleAvailable}
                      experience={guide.experience}
                      kd_id={guide.kd_id}
                      price={guide.price}
                    />
                  </Col>
                ))}
              </Row>
              <div ref={ref} className=" text-center mx-auto loading-indicator">
                {hasMore && (
                  <div className="flex justify-center items-center">
                    <Spin />
                    <p>Loading more data...</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetGuideBookingData = async (bookingid: any) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Booking/get-booking-data.php?bookingid=${bookingid}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

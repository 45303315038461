import { useGenerateStars } from "../../../src/Context";
import React from "react";
import { Slider } from "antd";

export const ReviewCategory = ({ data }) => {
  const generateStars = useGenerateStars();

  // Convert the data object into arrays
  const categories = data ? Object.keys(data) : [] ;
  const values = data ? Object.values(data).map(Number) : [];

  return (
    <>
      {categories.map((cat, index) => (
        <div className="w-full flex flex-col mb-4" key={index}>
          <label
            htmlFor={`review-${cat}`}
            className="my-0 py-0 font-medium text-gray-700"
          >
            {cat}
          </label>
          <div className="flex items-center my-0 py-0 justify-between">
            <Slider
              id={`review-${cat}`}
              min={1}
              max={5}
              value={values[index]}
              tooltipVisible={false}
              className="w-1/2"
              disabled
            />
            <span className="ml-2 flex items-center text-gray-700">
              <span>{generateStars(values[index])}</span>{" "}
              <span style={{ fontSize: "12px" }}>({values[index].toFixed(2)})</span> {/* Ensure two decimal places */}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

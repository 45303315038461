import { IMAGE_URL } from "../../../../src/Constant";
import React from "react";

export const AboutUs: React.FC = () => {
  return (
    <section className="py-12 px-4" id="about-us">
      <div className="text-center mb-12">
        <p className="text-lg font-semibold text-gray-700">
          Connecting You with Knowledgeable Guides.
        </p>
        <h2 className="text-3xl font-bold text-gray-900">
          About <span className="text-blue-600">Us</span>
        </h2>
      </div>
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-8">
        <div className="flex-1 bg-gradient-to-r from-blue-300 via-pink-200 to-sky-200 opacity-50 w-full py-10">
          <div className="container mx-auto text-center lg:text-left">
            <p className="text-black text-center text-lg md:text-lg">
              Welcome to Trained Guide, where your travel experience is our top
              priority. We specialize in connecting you with expert guides who
              are passionate about sharing their knowledge and love for Sri
              Lanka. Whether you're exploring ancient ruins, bustling markets,
              or serene landscapes, our guides ensure you gain insightful,
              enriching experiences. <br />
              At Trained Guide, we are committed to providing personalized and
              professional guide services to make your journey memorable. Our
              platform offers an easy and reliable way to book experienced
              guides who cater to your interests and needs. Join us and discover
              the hidden gems and vibrant culture of Sri Lanka with the help of
              our dedicated team.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

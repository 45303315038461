import React from "react";
import { Card, Row, Col, Typography } from "antd";
import {ReviewCard} from "../../../../src/components/ReviewCard";
import { IReviewCard } from "../../../../src/Constant";

export const CustomersSection = ({
  reviewData,
}: {
  reviewData: IReviewCard[];
}) => {
  return (
    <section className="py-10 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <p className="text-lg font-semibold text-gray-700">Our Customers</p>
          <h2 className="text-3xl font-bold text-gray-900">
            Customer's <span className="text-blue-600">Review</span>
          </h2>
        </div>
        <Row gutter={[16, 16]}>
          {reviewData.map((data, index) => (
            <Col span={24} md={12} lg={8} key={index}>
              <Card className="shadow-lg rounded-lg">
                <ReviewCard {...data} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

import * as React from "react";
import { cn, scrollToTop } from "../../lib/utils";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu";
import { Link } from "react-router-dom";
import {
  ADMIN_GUIDE_MANAGE,
  ADMINACCOUNTS,
  ADMINUSERMANAGE,
  DASHBOARD,
} from "../../Constant";
import { TopNav } from "./Top-nav";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export function AdminNavBar() {
  const { user } = useKindeAuth();
  return (
    <div className="text-center flex flex-col justify-center items-center sticky top-0 z-[998]">
      <TopNav />
      <div className="pt-3 pb-1 bg-white w-full flex justify-center">
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <Link to={`${DASHBOARD}/${user?.id}`}>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  onClick={scrollToTop}
                >
                  Dashboard
                </NavigationMenuLink>
              </Link>
              <Link to={`${ADMIN_GUIDE_MANAGE}/${user?.id}`}>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  onClick={scrollToTop}
                >
                  Guide manage
                </NavigationMenuLink>
              </Link>
              <Link to={ADMINUSERMANAGE}>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  onClick={scrollToTop}
                >
                  Kinde (Auth)
                </NavigationMenuLink>
              </Link>
              <Link to={ADMINACCOUNTS}>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  onClick={scrollToTop}
                >
                  payhere
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </div>
  );
}

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof Link> & { title: string }
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  IAdminreviewStatus,
  IBookingStatus,
  IMAGE_URL,
} from "../Constant";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const removeHtmlTags = (input: any) => {
  return input?.replace(/<[^>]*>?/gm, ""); // Remove HTML tags
};

export const truncate = (str: string, n: number) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const getProfileStatusColor = (status: any) => {
  switch (status) {
    case IAdminreviewStatus.pending:
      return "processing";
    case IAdminreviewStatus.rejected:
      return "red";
    case IAdminreviewStatus.accepted:
      return "success";
    case IAdminreviewStatus.blocked:
      return "error";
    default:
      return "cyan";
  }
};

export const getBookingStatusColor = (status: IBookingStatus) => {
  switch (status) {
    case IBookingStatus.requested:
      return "gold"; // Requested: Gold
    case IBookingStatus.rejected:
      return "red"; // Rejected: Red
    case IBookingStatus.cancelled:
      return "gray"; // Cancelled: Gray
    case IBookingStatus.accepted:
      return "green"; // Accepted: Green
    case IBookingStatus.firstPaymentPending:
      return "volcano"; // First payment pending: Volcano
    case IBookingStatus.firstPaymentDone:
      return "blue"; // First Payment Done: Blue
    case IBookingStatus.secondPaymentDone:
      return "geekblue"; // Second Payment Done: GeekBlue
    case IBookingStatus.tourCompleted:
      return "purple"; // Tour Completed: Purple
    case IBookingStatus.refundRequested:
      return "orange"; // Refund Requested: Orange
    case IBookingStatus.refundCompleted:
      return "cyan"; // Refund Completed: Cyan
    case IBookingStatus.refundRejected:
      return "volcano"; // Refund Rejected: Volcano
    case IBookingStatus.refundRejected:
      return "volcano"; // Refund Rejected: Volcano
    default:
      return "default"; // Default: Default
  }
};

export const prepareImageList = (images: any) =>
  images.map((img: any, index: any) => {
    if (img.thumbUrl) {
      return {
        uid: img.uid,
        name: img.name,
        thumbUrl: img.thumbUrl,
        originFileObj:img.originFileObj
      };
    } else {
      return {
        uid: `${index}`,
        name: img,
        url: `${IMAGE_URL}/upload/${img}`,
      };
    }
  });

export const getCurrentTime = () => {
  const now = new Date();

  // Format the date and time
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  // Construct the formatted date-time string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

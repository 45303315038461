import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Modal,
  Popconfirm,
  Image,
  List,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../../src/Constant";
import { prepareImageList } from "../../../src/lib";

const { TextArea } = Input;

const VehicleForm = ({ vehicles, setVehicles }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    return isJpgOrPng;
  };

  const handlePreview = (file) => {
    const previewUrl =
      file.url || URL.createObjectURL(file.originFileObj) || file.thumbUrl;
    setPreviewImage(previewUrl);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const showVehicleModal = (vehicle: any) => {
    setCurrentVehicle(vehicle);
    setIsModalVisible(true);
    if (vehicle) {
      form.setFieldsValue(vehicle);
      setFileList(prepareImageList(vehicle.images || []));
    } else {
      form.resetFields();
      setFileList([]);
    }
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        const updatedValues = {
          ...values,
          images: fileList.map((file: any) =>
            file?.thumbUrl ? file : file.name
          ),
        };

        if (currentVehicle) {
          setVehicles(
            vehicles.map((v) =>
              v.key === currentVehicle.key
                ? { ...v, ...updatedValues, key: currentVehicle.key }
                : v
            )
          );
        } else {
          // Add new vehicle
          const newVehicle = { ...updatedValues, key: Date.now().toString() };
          setVehicles([...vehicles, newVehicle]);
        }

        setIsModalVisible(false);
        setCurrentVehicle(null);
        form.resetFields();
      })
      .catch((error) => {
        toast.warning("Please fill all required fields.");
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCurrentVehicle(null);
    form.resetFields();
  };

  const handleRemoveVehicle = (vehicleKey) => {
    setVehicles(vehicles.filter((v) => v.key !== vehicleKey));
  };

  return (
    <div
      style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}
      className="shadow-md"
    >
      <Form layout="vertical" initialValues={{ ...vehicles }}>
        <List
          dataSource={vehicles}
          renderItem={(vehicle: any, key: any) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => showVehicleModal(vehicle)}
                />,
                <Popconfirm
                  title="Are you sure you want to remove this vehicle?"
                  onConfirm={() => handleRemoveVehicle(vehicle.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" danger icon={<DeleteOutlined />}>
                    Remove
                  </Button>
                </Popconfirm>,
              ]}
            >
              <div className="flex flex-col">
                <div style={{ marginBottom: "16px" }}>
                  <div className="col-span-1">
                    <div>
                      <span className="font-bold">Name:</span>{" "}
                      {vehicle.vehicle_name}
                    </div>
                    <div>
                      <span className="font-bold">Price per day:</span> $
                      {vehicle.vehicle_price}
                    </div>
                    <div>
                      <span className="font-bold">Note:</span>{" "}
                      {vehicle.vehicle_note}
                    </div>
                  </div>
                </div>

                <div className="my-2 flex">
                  {vehicle?.images &&
                    vehicle?.images.map((img: any, key: any) => (
                      <Image
                        key={key}
                        src={
                          img?.thumbUrl
                            ? img.thumbUrl
                            : `${IMAGE_URL}/upload/${img}`
                        }
                        alt={`Vehicle ${key + 1}`}
                        preview={false}
                        onClick={() =>
                          handlePreview({
                            originFileObj: img?.originFileObj
                              ? img.originFileObj
                              : `${IMAGE_URL}/upload/${img}`,
                            url: !img?.originFileObj
                              && `${IMAGE_URL}/upload/${img}`,
                          })
                        }
                        style={{
                          width: "100px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    ))}
                </div>
              </div>
            </List.Item>
          )}
        />

        {vehicles.length < 4 ? (
          <Button
            type="dashed"
            className="shadow-lg py-5"
            onClick={() => showVehicleModal(null)}
            style={{ width: "100%" }}
          >
            <PlusOutlined /> Add Vehicle
          </Button>
        ) : (
          <p className="pb-0 mb-0 text-center mt-3">
            You have reached the maximum vehicle limit (Max : 3).
          </p>
        )}

        <Modal
          visible={previewVisible}
          style={{ zIndex: 1050 }}
          title="Image Preview"
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>

        <Modal
          style={{ zIndex: 1000 }}
          title={currentVehicle ? "Edit Vehicle" : "Add Vehicle"}
          visible={isModalVisible}
          onOk={() => {
            if (fileList.length >= 3) {
              handleModalOk();
            } else {
              toast.info("Please add exactly 3 images.");
            }
          }}
          onCancel={handleModalCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Vehicle Name"
              name="vehicle_name"
              rules={[
                { required: true, message: "Please enter the vehicle name" },
              ]}
            >
              <Input placeholder="Enter vehicle name" />
            </Form.Item>

            <Form.Item
              label="Price per Day ($)"
              name="vehicle_price"
              rules={[
                { required: true, message: "Please enter the price per day" },
              ]}
            >
              <InputNumber
                min={0}
                placeholder="Enter price per day"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label="Note"
              name="vehicle_note"
              rules={[
                {
                  required: true,
                  message: "Please enter a short note about your vehicle",
                },
              ]}
            >
              <TextArea
                rows={3}
                placeholder="Enter any notes about the vehicle"
              />
            </Form.Item>

            <Form.Item
              label="Vehicle Images"
              name="images"
              rules={[
                {
                  required: true,
                  message: "Please add at least 3 vehicle images",
                },
              ]}
            >
              <Upload
                accept="image/*"
                listType="picture-card"
                fileList={fileList}
                onChange={handleUpload}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                multiple
                maxCount={4}
                showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              >
                {fileList.length < 4 && uploadButton}
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      </Form>
    </div>
  );
};

export default VehicleForm;

import React from "react";
import { Header } from "./common/Header";
import { HowToReg } from "./common/How-to-book";
import { Services } from "./common/Services";
import { AboutUs } from "./common/About-Us";
import { ImageGallery } from "../../components/ImageGallery/Gallery";
import { ContactUs } from "./common/Contact-Us";
import FAQSection from "./common/FAQSection";
import { CustomersSection } from "./common/Customer-section";

export const HomePage = () => {
  const fakeReviewData = [
    {
      name: "John Doe",
      review:
        "The tour was amazing! Our guide was very knowledgeable and made the whole experience enjoyable. Highly recommend to everyone!",
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
      rate: 4.21,
    },
    {
      name: "Jane Smith",
      review:
        "A fantastic experience from start to finish. The itinerary was well-organized, and the sights were breathtaking. Will definitely book again!",
      avatar: "https://randomuser.me/api/portraits/women/2.jpg",
      rate: 4.6,
    },
    {
      name: "Michael Johnson",
      review:
        "Great service and excellent value for money. The tour guide was friendly and provided lots of interesting information. Five stars!",
      avatar: "https://randomuser.me/api/portraits/men/3.jpg",
      rate: 4.8,
    },
    {
      name: "Emily Davis",
      review:
        "I had a wonderful time on the tour. Everything was well-planned, and the tour guide was very professional. Highly recommended!",
      avatar: "https://randomuser.me/api/portraits/women/4.jpg",
      rate: 3.9,
    },
    {
      name: "Chris Brown",
      review:
        "The tour exceeded my expectations. The guide was engaging, and the locations were well-chosen. An unforgettable experience!",
      avatar: "https://randomuser.me/api/portraits/men/5.jpg",
      rate: 4.5,
    },
    {
      name: "Don Robot",
      review:
        "The tour exceeded my expectations. The guide was engaging, and the locations were well-chosen. An unforgettable experience!",
      avatar: "https://randomuser.me/api/portraits/men/6.jpg",
      rate: 5.0,
    },
  ];

  const images = [
    {
      id: 1,
      src: "trainedguide_78616_kp_9ef2f28da43a4c1fa7d320ef59e0c149.png",
      alt: "Image 1",
    },
    {
      id: 2,
      src: "trainedguide_80656_kp_4b6e82d299e94e5285e04d67c1b02c06.png",
      alt: "Image 2",
    },
    {
      id: 3,
      src: "trainedguide_38862_kp_9ef2f28da43a4c1fa7d320ef59e0c149.png",
      alt: "Image 3",
    },
    {
      id: 4,
      src: "trainedguide_58374_kp_9ef2f28da43a4c1fa7d320ef59e0c149.png",
      alt: "Image 2",
    },
    {
      id: 5,
      src: "trainedguide_93588_kp_4b6e82d299e94e5285e04d67c1b02c06.png",
      alt: "Image 3",
    },
    {
      id: 6,
      src: "trainedguide_98172_kp_94ba6f3120ea4a52aa8b16c0d16ac817.jpg",
      alt: "Image 2",
    },
    {
      id: 7,
      src: "trainedguide_81109_kp_e7659bc19398448da800f5846e776c22.jpg",
      alt: "Image 2",
    },
    {
      id: 8,
      src: "trainedguide_21859_kp_e7659bc19398448da800f5846e776c22.jpg",
      alt: "Image 3",
    },
    ,
    {
      id: 9,
      src: "trainedguide_31241_kp_1ef3ba3cd2b543b0a90375d1cbf11292.jpg",
      alt: "Image 2",
    },
  ];

  return (
    <>
      <Header />
      <HowToReg />
      <Services />
      <AboutUs />
      <ImageGallery imagesArray={images} />
      <ContactUs />
      <FAQSection />
      <CustomersSection reviewData={fakeReviewData} />
    </>
  );
};

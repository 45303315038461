import { LoadingOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Button,
  Drawer,
  Modal,
  Tooltip,
  Upload,
  Input,
  Form,
  Row,
  Col,
  Checkbox,
  Spin,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import {
  genderOptions,
  IMAGE_URL,
  TERMSPAGEURL,
  YT_TUTORIAL,
} from "../../../src/Constant";
import {
  AddGuestBioData,
  GetAllCountryData,
  GetGuestBioDataForProfile,
} from "../../../src/Api";
import { WhatsApp } from "../Whatsapp/WhatsApp";
import { ProfileIcon } from "../Profile-icon/Profile-icon";

export const GuestProfile = () => {
  const { userid } = useParams();
  const [visible, setVisible] = useState(false);
  const { user } = useKindeAuth();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState([]);

  //image handle
  const [profileImage, setProfileImage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  //pofile data load
  const [profileData, setProfileData] = useState<any>();

  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await GetGuestBioDataForProfile(userid);
      const reagonData = await GetAllCountryData();

      //profile data
      setProfileData(response);
      if (response) {
        if (response?.userBioData.profileImage) {
          setProfileImage([
            {
              uid: "-1",
              name: "profileImage.png",
              status: "done",
              url: `${IMAGE_URL}/upload/${response.userBioData.profileImage}`,
            },
          ]);
        } else {
          setProfileImage([]);
        }
      }

      //set country
      const countryOptions = reagonData
        .map((country) => ({
          value: country.cca2,
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={country.flags.svg}
                alt={`Flag of ${country.name.common}`}
                style={{ width: "20px", marginRight: "10px" }}
              />
              {country.name.common}
            </div>
          ),
          name: country.name.common,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      const sortedCountryOptions = countryOptions.map(
        ({ name, ...rest }) => rest
      );
      setCountries(sortedCountryOptions);
    } catch (error) {
      console.log(error);
      toast.error("Somthing went wrong.please try againg later");
    } finally {
      setTimeout(() => setIsLoading(false), 2000);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchProfileData();
    }
  }, [visible]);

  const handleCheckboxChange = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const handleUpload =
    (fileListSetter) =>
    ({ fileList }) => {
      fileListSetter(fileList);
    };

  const imageUploadButton = (text: string) => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {text}</div>
    </div>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  //   Bio section
  const [aboutUs, setAboutUs] = useState("");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const profileData = {
      ...values,
      userId: user?.id,
    };
    try {
      const response = await AddGuestBioData(profileData);
      if (response?.isDataSet && response?.isDataAdded) {
        toast.success(response?.msg);
        fetchProfileData();
      } else {
        toast.error(response?.msg);
      }
    } catch (e) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setTimeout(() => setIsLoading(false), 3000);
    }
  };

  const handleFormSubmitUpdate = () => {
    toast.success("update");
  };

  return (
    <>
      <div className="flex justify-stat md:justify-end shadow-lg">
        <ProfileIcon showDrawer={showDrawer} />
        <WhatsApp />
      </div>
      {/* Drawer */}
      <Drawer
        title={
          <div className="hover:cursor-pointer flex justify-between items-center">
            <p className="mb-0">Profile </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        width={600}
      >
        <>
          {/* Bio Data add */}
          {isLoading ? (
            <>
              <div className="flex justify-center flex-col gap-y-10 items-center h-full">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
                <p>Profile data is loading. Please wait a moment.</p>
              </div>
            </>
          ) : (
            <div>
              <Form
                form={form}
                initialValues={profileData?.userBioData}
                layout="vertical"
                onFinish={handleFormSubmit}
              >
                <div>
                  <div className="text-right flex justify-between">
                    <Link
                      className="underline text-blue-500"
                      target="_blank"
                      to={`../../${TERMSPAGEURL}`}
                    >
                      Terms and condition
                    </Link>
                    <Link
                      className="underline text-blue-500"
                      target="_blank"
                      to={YT_TUTORIAL}
                    >
                      Instruction
                    </Link>
                    <p className="font-bold">
                      <span className="text-red-600">*</span> All fields are
                      required
                    </p>
                  </div>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Profile Image"
                        name="profileImage"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your profile image",
                          },
                        ]}
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={profileImage}
                          onChange={handleUpload(setProfileImage)}
                          beforeUpload={beforeUpload}
                          onPreview={handlePreview}
                        >
                          {profileImage.length >= 1
                            ? null
                            : imageUploadButton("Profile image")}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>

                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select your gender",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your gender"
                      style={{ width: "100%" }}
                    >
                      {genderOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Contact Number"
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your contact number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your contact number" />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item
                    label="Personal Address"
                    name="personalAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your personal address",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your personal address" />
                  </Form.Item>

                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please select your country",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your country"
                      style={{ width: "100%" }}
                    >
                      {countries.map((country) => (
                        <Select.Option
                          key={country.value}
                          value={country.value}
                        >
                          {country.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="About You"
                    name="aboutYou"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter a description about you and your service",
                      },
                    ]}
                  >
                    <ReactQuill
                      value={aboutUs}
                      onChange={setAboutUs}
                      placeholder="Tell us about yourself"
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  name="acceptTerms"
                  valuePropName="checked"
                  className="my-5"
                >
                  <Checkbox onChange={handleCheckboxChange}>
                    I accept the terms and conditions
                  </Checkbox>
                </Form.Item>

                <Form.Item className="text-end my-[5vh]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isTermsAccepted}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </>
      </Drawer>

      {/*image privew*/}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

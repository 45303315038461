import { GetAllGalleryImage } from "../../../src/Api";
import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { IMAGE_URL } from "src/Constant/AppConstants";

export const GalleryPage = () => {
  const [displayedImages, setDisplayedImages] = useState(15);
  const [loading, setLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState(
    Array(galleryImages.length).fill(true)
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const galleryImages = await GetAllGalleryImage();
      setGalleryImages(galleryImages?.Image);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setDisplayedImages((prev) => prev + 9);
    }
  }, [inView]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleImageLoad = (index) => {
    const newLoadingStatus = [...loadingStatus];
    newLoadingStatus[index] = false;
    setLoadingStatus(newLoadingStatus);
  };

  return (
    <section className="mt-5">
      {galleryImages?.length <= 0 ? (
        <div className="flex justify-center items-center h-[80vh]">
          <Empty />
        </div>
      ) : (
        <>
          {!loading ? (
            <div className="container-fluid px-4 sm:px-6 md:px-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {galleryImages &&
                  galleryImages
                    .slice(0, displayedImages)
                    .map((image, index) => (
                      <div
                        key={index}
                        className="relative overflow-hidden cursor-pointer group"
                        onClick={() => showModal(image)}
                      >
                        <img
                          src={`${IMAGE_URL}/upload/${image?.src}`}
                          alt={image?.alt}
                          onLoad={() => handleImageLoad(index)}
                          className="w-full h-48 object-cover rounded-lg transition-transform transform hover:scale-105"
                        />

                        {loadingStatus[index] && (
                          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                            <Spin size="small" />
                          </div>
                        )}

                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <EyeOutlined className="text-white text-xs" />
                          <span className="text-white text-xs mt-1">
                            Preview
                          </span>
                        </div>
                      </div>
                    ))}
                <div ref={ref} className="col-span-full h-1"></div>
              </div>

              <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={"80vh"}
                height={"80vh"}
                centered
              >
                <img
                  src={`${IMAGE_URL}/upload/${currentImage?.src}`}
                  alt={currentImage?.alt}
                  className="w-full h-auto p-0 m-0"
                />
              </Modal>
            </div>
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            />
          )}
        </>
      )}
    </section>
  );
};

import { toast } from "react-toastify";
import { SERVER_API } from "../../../src/Constant";
import axios from "axios";

export const AuthCheck = async (data: any) => {
  try {
    const formData = new FormData();
    formData.append("inputs", JSON.stringify(data));

    const dataObject = await axios.post(
      `${SERVER_API}/Auth/Auth-check.php`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataObject.data;
  } catch (error: any) {
    toast.error("Somthing went wrong");
  }
};

import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetGuestBioDataForProfile = async (guestid) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Guest/get-guest-bio-data_for_profile.php?guestid=${guestid}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

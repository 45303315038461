import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Tag,
  Calendar,
  Typography,
  Table,
  Form,
  Input,
  Button,
  Spin,
  Empty,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import {
  FIRSTPAYMENT,
  IBookingStatus,
  UserRolesEnum,
} from "../../../src/Constant";
import { useMediaQuery } from "@mui/material";
import { AddBookingStatus, GetGuideBookingData } from "../../../src/Api";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { PaymentDetails } from "../payment-details/payment-details";
import { getBookingStatusColor } from "../../../src/lib";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { HrLine } from "../Hr-line/HrLine";
import { BookingStatusSteps } from "../Booking-status/BookingStatus";
import { PaymentHistory } from "../paymenthistory/PaymentHistory";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const BookingDetails = ({ selectedMessageId }) => {
  const [form] = Form.useForm();
  const isMobile = useMediaQuery("(max-width:600px)");
  const user_role = sessionStorage.getItem("user_role");

  const [bookingData, setBookingData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<any>({});
  const [bookingStatus, setBookingStatus] = useState<any>();

  useEffect(() => {
    if (selectedMessageId) {
      fetchData();
    }
  }, [selectedMessageId, bookingStatus]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await GetGuideBookingData(selectedMessageId);
      const data = response?.bookingData[0];
      setBookingData(data);
      setBookingStatus(data?.status);
      setBookingInfo(data?.bookinginfo);
    } catch {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const selectedDates = [
    dayjs(bookingInfo?.start_date),
    dayjs(bookingInfo?.end_date),
  ];

  const startDate = dayjs.utc(bookingInfo?.start_date).local().startOf("day");
  const endDate = dayjs.utc(bookingInfo?.end_date).local().endOf("day");

  const isDateInRange = (date) => {
    return (
      date?.isSameOrAfter(startDate, "day") &&
      date?.isSameOrBefore(endDate, "day")
    );
  };

  const dateCellRender = (value) => {
    return isDateInRange(value) ? (
      <Tag color="blue" className="text-white">
        Booked
      </Tag>
    ) : null;
  };
  const defaultMonth = selectedDates[0] || dayjs();

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
      render: (text) => (
        <div className="text-start font-bold pr-10 whitespace-nowrap">
          {text} :
        </div>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text) => (
        <div className="text-start ms-[1vw] font-bold">{text}</div>
      ),
    },
  ];

  const data = [
    { key: "1", field: "Day Count", value: bookingInfo?.daycount },
    { key: "2", field: "Adults Count", value: bookingInfo?.adults_count },
    { key: "3", field: "Children Count", value: bookingInfo?.children_count },
    {
      key: "4",
      field: "Guide Cost",
      value: `$ ${bookingInfo?.guidecost}`,
    },
    { key: "5", field: "Selected Language", value: bookingInfo?.language },
    { key: "6", field: "Selected Vehicle", value: bookingData?.vehiclename },
    {
      key: "7",
      field: "Vehicle Cost",
      value: `$ ${bookingInfo?.vehiclecost}`,
    },
    { key: "8", field: "Selected Package", value: bookingData?.packagename },
    {
      key: "9",
      field: "Package Cost",
      value: `$ ${bookingInfo?.packagecost}`,
    },
    {
      key: "10",
      field: "Total Estimated Cost",
      value: `$ ${bookingInfo?.totalcost}`,
    },
  ];

  const handleConfirmBookingStatus = async (values, status) => {
    const data = {
      ...values,
      status,
      finalamount:
        isNaN(Number(bookingInfo?.totalcost)) ||
        isNaN(Number(values?.additionalCharge)) ||
        isNaN(Number(values?.offer))
          ? bookingData?.finalamount
          : (
              Number(bookingInfo?.totalcost) +
              Number(values?.additionalCharge) -
              Number(values?.offer)
            ).toFixed(2),
      selectedMessageId,
    };

    const responce = await AddBookingStatus(data);
    setBookingStatus(status);

    if (responce && responce?.isDataSet) {
      toast.success(responce?.msg);
    } else {
      toast.success("Somthing went wrong!");
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center flex-col gap-y-10 items-center h-full">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          <p>Booking data is loading. Please wait a moment.</p>
        </div>
      ) : (
        <>
          {bookingData ? (
            <div className="px-4">
              <div className="flex items-center gap-x-10">
                <Typography.Title level={2}>Booking Details</Typography.Title>
                <Tag color={getBookingStatusColor(bookingStatus)} className="">
                  {bookingStatus}
                </Tag>
              </div>
              <Row gutter={16} className="flex flex-col md:flex-row">
                <Col span={isMobile ? 24 : 12}>
                  <Card title="Booking Dates" className="mb-4">
                    <div className="mb-3 flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between text-center">
                      <div>
                        <Tag color="blue">{startDate.format("YYYY-MM-DD")}</Tag>{" "}
                        to {"  "}
                        <Tag color="blue" className="ms-2">
                          {endDate.format("YYYY-MM-DD")}
                        </Tag>
                      </div>

                      <div>
                        <p className="font-bold">
                          Total Amount ${bookingInfo?.totalcost}
                        </p>
                      </div>
                    </div>

                    <Calendar
                      fullscreen={false}
                      disabledDate={disabledDate}
                      dateCellRender={dateCellRender}
                      defaultValue={defaultMonth}
                    />

                    <div className="my-3">
                      {(bookingStatus === IBookingStatus.requested ||
                        bookingStatus === IBookingStatus.accepted) &&
                        user_role === UserRolesEnum.guide && (
                          <Form
                            form={form}
                            layout="vertical"
                            initialValues={{ ...bookingData }}
                          >
                            <div className="flex flex-row justify-between">
                              <Form.Item
                                label="Additional Charge"
                                name="additionalCharge"
                                initialValue={0}
                              >
                                <Input placeholder="Enter additional charge" />
                              </Form.Item>

                              <Form.Item
                                label="Offer"
                                name="offer"
                                initialValue={0}
                              >
                                <Input placeholder="Enter offer amount" />
                              </Form.Item>
                            </div>
                            <Form.Item
                              label="Special Note"
                              name="specialNote"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the note!",
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder="Enter any special notes"
                                rows={4}
                              />
                            </Form.Item>

                            <div>
                              {bookingStatus === IBookingStatus.requested && (
                                <>
                                  <Form.Item>
                                    <Popconfirm
                                      title="Are you sure you want to confirm this booking?"
                                      onConfirm={() => {
                                        handleConfirmBookingStatus(
                                          form.getFieldsValue(),
                                          IBookingStatus.accepted
                                        );
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Button type="primary" className="w-full">
                                        Confirm Booking
                                      </Button>
                                    </Popconfirm>
                                  </Form.Item>
                                </>
                              )}

                              <Form.Item>
                                <Popconfirm
                                  title="Are you sure you want to reject this booking?"
                                  onConfirm={() => {
                                    handleConfirmBookingStatus(
                                      form.getFieldsValue(),
                                      IBookingStatus.rejected
                                    );
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    type="primary"
                                    danger
                                    className="w-full"
                                  >
                                    Reject Booking
                                  </Button>
                                </Popconfirm>
                              </Form.Item>
                            </div>
                          </Form>
                        )}
                      {(bookingStatus === IBookingStatus.requested ||
                        bookingStatus === IBookingStatus.accepted) &&
                        user_role === UserRolesEnum.guest && (
                          <>
                            <Form form={form} layout="vertical">
                              <Form.Item
                                label="Special Note"
                                name="specialNote"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the note!",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  placeholder="Enter any special notes"
                                  rows={4}
                                />
                              </Form.Item>

                              <div>
                                <Form.Item>
                                  <Popconfirm
                                    title="Are you sure you want to cancel this booking?"
                                    onConfirm={() =>
                                      handleConfirmBookingStatus(
                                        form.getFieldsValue(),
                                        IBookingStatus.cancelled
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      type="primary"
                                      danger
                                      className="w-full"
                                    >
                                      Cancel Booking
                                    </Button>
                                  </Popconfirm>
                                </Form.Item>
                              </div>
                            </Form>
                          </>
                        )}
                    </div>
                    <PaymentDetails bookingData={bookingData} selectedMessageId={selectedMessageId} fetchData={fetchData} />
                  </Card>
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <Card
                    title="Booking Information"
                    className="mb-4"
                    bordered={false}
                  >
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      showHeader={false}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "bg-gray-100" : "bg-white"
                      }
                      size="small"
                    />
                    <p className="mt-3">
                      <span className="font-bold">Note :</span>{" "}
                      {bookingInfo?.note}
                    </p>
                  </Card>
                  <Card>
                    <div className="my-4 p-4 bg-gray-100 rounded-lg shadow-md">
                      {/* additional charges */}
                      {(bookingData?.additionalCharge != 0 ||
                        user_role == UserRolesEnum.guide) && (
                        <div className="flex justify-between items-center py-2">
                          <span className="text-lg font-semibold text-gray-700">
                            Additional charge
                          </span>
                          <span className="text-lg font-semibold text-gray-700">
                            ${bookingData?.additionalCharge}
                          </span>
                        </div>
                      )}

                      {/* offers  */}
                      {(bookingData?.offer != 0 ||
                        user_role == UserRolesEnum.guide) && (
                        <div className="flex justify-between items-center py-2">
                          <span className="text-lg font-semibold text-gray-700">
                            Offer
                          </span>
                          <span className="text-lg font-semibold text-gray-700">
                            ${bookingData?.offer}
                          </span>
                        </div>
                      )}

                      {/* Total Amount  */}
                      {bookingData?.finalamount != 0 && (
                        <div className="flex justify-between items-center py-2">
                          <span className="text-lg font-semibold text-gray-700">
                            Total Amount
                          </span>
                          <span className="text-lg font-semibold text-gray-700">
                            ${bookingData?.finalamount}
                          </span>
                        </div>
                      )}

                      {/* special note  */}
                      {bookingData?.specialNote && (
                        <div className="flex justify-between items-start py-2">
                          <span className="text-md font-medium text-gray-700">
                            Note : {bookingData?.specialNote}
                          </span>
                        </div>
                      )}

                      {/* guide note */}
                      {user_role == UserRolesEnum.guide && (
                        <>
                          <HrLine />
                          <small className="mt-2">
                            <i>
                              This is for the total cost (Commission + Guide
                              payment). The platform will collect the first
                              payment ({FIRSTPAYMENT}%). You can receive your
                              payment at the start of the tour or as needed. If
                              you have any doubts, please refer to our guide
                              terms and conditions in the top section of the
                              profile, or contact our team.
                            </i>
                          </small>
                        </>
                      )}
                    </div>
                  </Card>

                  <Card>
                    <div className="my-4 p-4 bg-gray-100 rounded-lg shadow-md">
                      <h2 className="text-lg font-bold mb-4">Booking Status</h2>
                      <BookingStatusSteps
                        statusArray={bookingData?.status_array}
                      />
                    </div>
                  </Card>
                  <PaymentHistory paymentHistory={bookingData?.paymentData} />
                </Col>
              </Row>
            </div>
          ) : (
            <div className="flex justify-center flex-col gap-y-10 items-center h-full">
              <Empty />
            </div>
          )}
        </>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { IMAGE_URL } from "../../../Constant";

export const Services: React.FC = () => {
  const [shuffledServices, setShuffledServices] = useState([]);

  useEffect(() => {
    setShuffledServices(shuffleArray([...services]));
  }, []);

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const services = [
    {
      id: 1,
      image: "service-tour-guide.png",
      title: "Local Expert Guides",
      subtitle: `Connect with knowledgeable local guides who provide in-depth insights into your travel destinations.`,
    },
    {
      id: 2,
      image: "service-Itineraries.png",
      title: "Custom Itineraries",
      subtitle: `Tailor your travel experience with personalized itineraries designed to match your interests and preferences.`,
    },
    {
      id: 3,
      image: "service-public-transport.png",
      title: "Transportation Assistance",
      subtitle: `Enjoy seamless travel with our transportation services, including airport pickups and transfers to various destinations.`,
    },
    {
      id: 4,
      image: "service-Cultural.png",
      title: "Cultural Experiences",
      subtitle: `Immerse yourself in local culture through guided tours, cooking classes, and traditional activities.`,
    },
    {
      id: 5,
      image: "service-24-hours-support.png",
      title: "24/7 Support",
      subtitle: `Receive round-the-clock support from our team to ensure your trip goes smoothly and all your needs are met.`,
    },
    {
      id: 6,
      image: "service-Local-Dining.png",
      title: "Local Dining Recommendations",
      subtitle: `Get recommendations for the best local dining spots and enjoy authentic cuisine experiences.`,
    },
    {
      id: 7,
      image: "service-Adventure-Tours.png",
      title: "Adventure Tours",
      subtitle: `Explore thrilling adventures such as hiking, diving, and wildlife safaris with our experienced guides.`,
    },
    {
      id: 8,
      image: "service-insurance.png",
      title: "Safety Assured",
      subtitle: `Travel with peace of mind knowing that our guides adhere to strict safety protocols and standards.`,
    },
    {
      id: 9,
      image: "service-feedback.png",
      title: "Feedback System",
      subtitle: `Share your feedback and reviews to help us continuously improve our services and guide recommendations.`,
    },
  ];

  return (
    <section className="py-12 px-4 md:px-8 bg-gray-100" id="services">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <p className="text-lg font-semibold text-gray-700">
            Best tourGuide booking system in Sri Lanka
          </p>
          <h2 className="text-3xl font-bold text-gray-900">
            Our <span className="text-blue-600">Service</span>
          </h2>
        </div>

        <div className="flex flex-wrap justify-center gap-6">
          {shuffledServices.map((service, index) => (
            <div
              key={service.id}
              className="w-full sm:w-80 md:w-1/3 lg:w-1/4 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105"
              data-aos="fade-up"
              data-aos-duration={500 + 100 * index}
              data-aos-delay={100 * index}
            >
              <img
                className="w-40 mt-2 mx-auto h-40 object-cover"
                src={`${IMAGE_URL}/${service.image}`}
                alt={service.title}
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600">{service.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

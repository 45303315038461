import { CalendarFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

export const GuideCalender = () => {
  return (
    <>
      <Tooltip placement="right" title={"Your Booking's calander"}>
        <Button
          type="default"
          shape="circle"
          size="large"
          style={{ padding: "1.6rem" }}
          className="shadow-lg mx-0 md:mx-5 fixed top-[38%] md:top-[32%] z-[99]"
          icon={
            <CalendarFilled
              style={{ fontSize: "1.6rem" }}
              className="text-blue-600"
            />
          }
        />
      </Tooltip>
    </>
  );
};

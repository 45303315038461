import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetGuideBioDataForProfile = async (guideId) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Guide/get-guide-bio-data_for_profile.php?guideid=${guideId}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

import { SERVER_API } from "../../Constant";
import axios from "axios";

export const generateHashValue = async (formData:any) => {  
    try {
      const response = await axios.get(
        `${SERVER_API}/Booking/load-hash-value.php?inputs=${JSON.stringify(formData)}`
      );
      
      return response.data;    
    } catch (error:any) {
      console.error("Error fetching data:", error.message);
      
    }
  };
  
import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const AddGuideStatus = async (formData: any) => {
    const data = {
        status:formData?.status,
        comment:formData?.comment,
        userId:formData?.userid
    }
  try {
    const response = await axios.get(
      `${SERVER_API}/Guide/add-guide-status.php?inputs=${JSON.stringify(
        data
      )}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const AddBookingStatus = async (formData: any) => {    
    const formDataSet = new FormData();
    formDataSet.append("inputs", JSON.stringify(formData));

    try {
        const response = await axios.post(
            `${SERVER_API}/Booking/add-booking-status.php`,
            formDataSet,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        toast.error("Something went wrong");
        console.error("API Error:", error);
    }
};

import React from "react";

export const useGenerateStars = (): ((rate: any) => JSX.Element[]) => {
  const generateStars = (rate: any): JSX.Element[] => {
    const fullStars = Math.floor(rate);
    const halfStar = rate % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    const stars: JSX.Element[] = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <i key={`full-${i}`} className="bi bi-star-fill text-yellow-400"></i>
      );
    }
    if (halfStar === 1) {
      stars.push(
        <i key="half" className="bi bi-star-half text-yellow-400"></i>
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <i key={`empty-${i}`} className="bi bi-star text-yellow-400"></i>
      );
    }
    return stars;
  };

  return generateStars;
};

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { HOMEPAGEURL, UserRoles, UserRolesEnum } from "../Constant";

export const addUserRoleToSession = (role: UserRoles) => {
  sessionStorage.setItem("user_role", role);
};

export const getUserRoleToSession = () => {
  sessionStorage.getItem("user_role");
};

export const useAuthCheck = (requiredUserId) => {
  const { user, isLoading } = useKindeAuth();
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("user_role");

  useEffect(() => {
    if (isLoading) return; // If still loading, do nothing

    // Redirect if no user is available
    if (!user) {
      navigate(HOMEPAGEURL);
      return;
    }

    // Redirect if the user ID doesn't match the required ID
    if (requiredUserId && user.id !== requiredUserId) {
      navigate(HOMEPAGEURL);
      return;
    }

    // Redirect if user_role is not 'guest' or 'guide'
    if (userRole !== UserRolesEnum.guest && userRole !== UserRolesEnum.guide && userRole !== UserRolesEnum.admin) {
      navigate(HOMEPAGEURL);
    }
  }, [user, isLoading, requiredUserId, userRole, navigate]);

  return {
    isAuthenticated: !!user, // Boolean flag indicating if the user is authenticated
    user, // The current user object
    userRole, // The role of the current user
    isLoading, // Boolean flag indicating if the user data is still loading
  };
};


import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const SendMessage = async (bookingId, chatData) => {
  try {
    const data = {
      bookingId: bookingId,
      chatData: chatData,
    };

    const formDataSet = new FormData();
    formDataSet.append("inputs", JSON.stringify(data));

    const response = await axios.post(
      `${SERVER_API}/chat/send-msg.php`,
      formDataSet,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

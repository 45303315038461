import React from "react";
import { Collapse, Typography } from "antd";
import { useMediaQuery } from "@mui/material";
import { YT_TUTORIAL } from "../../../../src/Constant";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const faqData = [
  {
    question: "How do I book a tour?",
    answer: `To book a tour using our system, please refer to the following link. If you have any questions or need further assistance, feel free to contact our support team.`,
    link: YT_TUTORIAL,
  },
  {
    question: "I want a private tour. What do I need to do?",
    answer: `You can request a private tour by selecting your desired tour guide and specifying your preference for a private tour. Simply browse our available guides, choose one, and send a request for a private tour.`,
  },
  {
    question: "What should I do if I feel unsafe during the tour?",
    answer: `If you feel unsafe during the tour, immediately inform your tour guide and move to a safer location. If the situation persists, contact our support team for assistance. Your safety is our top priority.`,
  },
  {
    question: "Do I need travel insurance?",
    answer: `While travel insurance is not mandatory, we highly recommend it for your safety and peace of mind. Travel insurance can cover unexpected events such as medical emergencies, trip cancellations, and lost luggage.`,
  },
  {
    question: "Can I customize my tour?",
    answer: `Yes, you can customize your tour based on your preferences. Contact your chosen tour guide to discuss and plan a personalized itinerary that suits your needs and interests.`,
  },
  {
    question: "What should I bring on the tour?",
    answer: `We recommend bringing comfortable clothing, walking shoes, a hat, sunscreen, water, and any personal items you may need. Your tour guide will provide specific recommendations based on the tour activities.`,
  },
];

const FAQSection: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className="container py-10">
      <div className="text-center mb-12">
        <p className="text-lg font-semibold text-gray-700">
          If you have any other questions, feel free to reach out to our support
          team.
        </p>
        <h2 className="text-3xl font-bold text-gray-900">
          Frequently Asked <span className="text-blue-600">Questions</span>
        </h2>
      </div>
      <Collapse
        accordion
        expandIconPosition="right"
        bordered={false}
        ghost
      >
        {faqData.map((faq, index) => (
          <Panel
            header={
              <div className="flex justify-between shadow-lg items-center w-full bg-blue-900 py-4 text-white px-3 rounded text-lg">
                <span>{faq.question}</span>
              </div>
            }
            key={index}
            className="faq-item border-b border-gray-200"
            style={{ marginBottom: "1rem" }}
          >
            <Paragraph className="text-center ">
              <p className="text-lg mx-5">{faq.answer}</p>
              {faq.link && (
                <iframe
                  width={isMobile ? "100%" : 600}
                  className="mt-5 text-center mx-auto shadow-lg rounded"
                  height={isMobile ? "auto" : 300}
                  src={faq.link}
                  allowFullScreen
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              )}
            </Paragraph>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQSection;

import React from "react";
import { Button, Card } from "antd";
import {
  ADDRESS,
  EMAIL,
  FACEBOOK,
  IMAGE_URL,
  INSTERGRAM,
  WHATSAPP,
} from "../../../../src/Constant/AppConstants";
import { Link } from "react-router-dom";

export const ContactUs = () => {
  return (
    <section className="contact-section py-12">
      <div className="text-center mb-12">
        <p className="text-lg font-semibold text-gray-700">For more info.</p>
        <h2 className="text-3xl font-bold text-gray-900">
          Contact Our <span className="text-blue-600">Team</span>
        </h2>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center lg:flex-row">
          <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
            <div
              className="text-start"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <p className="text-lg mb-4">
                We&apos;d love to hear from you! Whether you have questions
                about our tours, need assistance with bookings, or want to share
                feedback, our team is here to help.
              </p>

              <p className="text-lg mb-4">
                Our customer support team is available Monday to Friday from
                9:00 AM to 5:00 PM (local time) And{" "}
                <b>24Hrs Available via WhatsApp</b>. Feel free to reach out to
                us via email or phone, and we&apos;ll get back to you as soon as
                possible.
              </p>

              <div className="text-lg mb-4">
                <p className="mb-2">
                  <strong>Email: </strong>
                  <a
                    href={`mailto:${EMAIL}`}
                    className="text-blue-500 hover:underline"
                  >
                    {EMAIL}
                  </a>
                </p>
                <p className="mb-2">
                  <strong>Phone: </strong>
                  <a
                    href={`tel:${WHATSAPP}`}
                    className="text-green-500 hover:underline"
                  >
                    {WHATSAPP}
                  </a>
                </p>
                <p>
                  <strong>Office Address: </strong>
                  <span className="text-red-500">{ADDRESS}</span>
                </p>
              </div>

              <div className="mt-4">
                <p className="text-lg mb-2">
                  Follow us on social media for the latest updates and special
                  offers:
                </p>
                <div className="flex space-x-4">
                  <Link
                    to={FACEBOOK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 rounded-full bg-white shadow hover:bg-blue-50"
                  >
                    <i className="fa fa-facebook text-blue-600 text-2xl"></i>
                  </Link>
                  <Link
                    to={INSTERGRAM}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 rounded-full bg-white shadow hover:bg-pink-50"
                  >
                    <i className="fa fa-instagram text-pink-600 text-2xl"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2">
            <Card
              cover={
                <img
                  src={`${IMAGE_URL}/contactus_image.jpg`}
                  alt="Contact Us"
                  className="object-cover w-full h-full"
                />
              }
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { HOMEPAGEURL } from "../../../src/Constant";
import { scrollToTop } from "../../../src/lib";

export const NotFoundPage = () => (
  <div className="flex flex-col items-center justify-center h-[80vh] bg-gray-100">
    <div className="text-center">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <h2 className="text-2xl font-semibold mb-2">Page Not Found</h2>
      <p className="text-lg mb-8">
        The page you are looking for does not exist.
      </p>
      <Link to={HOMEPAGEURL}>
        <Button type="default" className="px-6 py-2 text-lg" onClick={scrollToTop}>
          Go to Home
        </Button>
      </Link>
    </div>
  </div>
);

export default NotFoundPage;

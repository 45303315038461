import { useGenerateStars } from "../../../src/Context";
import { Image, Spin } from "antd";
import React, { useState } from "react";
import {
  ArrowRightOutlined,
  DollarOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import {
  COMMISSION,
  GUIDEPUBLICPROFILE,
  IGuideCard,
  IMAGE_URL,
} from "../../../src/Constant";
import { Link } from "react-router-dom";
import { TruncatedTextWithTooltip } from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

export const Guidecard = ({
  kd_id,
  image,
  name,
  showerDescription,
  rate,
  type,
  isVehicleAvailable,
  experience,
  price,
}: IGuideCard) => {
  const generateStars = useGenerateStars();
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div className="flex w-100 hover:cursor-pointer pe-2 items-center justify-between flex-col md:flex-row w-full max-w-md md:max-w-sm m-2 bg-slate-50 shadow-md rounded-lg overflow-hidden hover:shadow-xl">
      <div className="flex flex-col text-center w-30 m-1 pt-3 md:pt-0 relative">
        {/* Spinner overlay */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-lg">
            <Spin size="small" />
          </div>
        )}

        {/* Image */}
        <Image
          src={`${IMAGE_URL}/upload/${image}`}
          height={100}
          width={100}
          className="object-cover w-full h-100 rounded-lg shadow-md"
          onLoad={handleImageLoad}
          style={{ display: loading ? "none" : "block" }} // Hide image until it's loaded
        />

        <div className="my-2">
          {generateStars(rate)} <br />({rate})
        </div>
      </div>
      <div className="ms-5 w-60">
        <h1 className="font-bold text-lg my-0 py-0">
          {TruncatedTextWithTooltip(name, 20)}
        </h1>
        <p style={{ fontSize: "12px" }}>
          {TruncatedTextWithTooltip(showerDescription, 150)}
        </p>

        <div className="flex justify-between">
          <ul className="space-y-1 flex flex-col justify-start text-gray-700">
            <li className="flex items-center justify-start font-bold">
              <DollarOutlined className="me-1" />
              <p className="my-0 py-0">{(price * COMMISSION).toFixed(2)}</p>
            </li>
            <li className="flex items-center justify-around ">
              <SafetyOutlined className="me-1" />
              <p className="my-0 py-0">{TruncatedTextWithTooltip(type, 10)}</p>
            </li>{" "}
          </ul>
          <ul className="space-y-1 flex flex-col justify-start text-gray-700">
            <li className="flex items-center">
              <i className="bi bi-car-front-fill me-2"></i>
              <p className="m-0">
                {isVehicleAvailable ? "Available" : "Not Available"}
              </p>
            </li>
            <li className="flex items-center">
              <i className="me-2 bi bi-passport-fill"></i>
              <p className="m-0">{experience}/Yr</p>
            </li>
          </ul>
        </div>
        <Link
          to={`../${GUIDEPUBLICPROFILE}/${kd_id}`}
          className="text-end flex justify-end text-blue-600 mb-2 font-bold"
        >
          Visit my Profile <ArrowRightOutlined className="ms-1" />
        </Link>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Button, Input, Form, List, Space, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import Column from "antd/es/table/Column";

const AddPackageForm = ({ packages, setPackages }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [dayNotes, setDayNotes] = useState([]);

  const handleRemovePackage = (key) => {
    setPackages(packages.filter((pack) => pack.key !== key));
  };

  const resetStates = () => {
    setDayNotes([]);
    setCurrentPackage(null);
    form.resetFields();
  };

  useEffect(() => {
    if (currentPackage) {
      setDayNotes(currentPackage.package_daynote || []);
      form.setFieldsValue({
        package_title: currentPackage.package_title,
        package_price: currentPackage.package_price,
        package_additionalNote: currentPackage.package_additionalNote,
        package_daynote: currentPackage.package_daynote.map((note) => ({
          dayNote: note.dayNote,
        })),
      });
    } else {
      resetStates();
    }
  }, [currentPackage, form]);

  const showPackageModal = (pack) => {
    setCurrentPackage(pack);
    if (!pack) {
      resetStates();
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const dayNotes = form.getFieldValue("package_daynote");
    if (dayNotes && dayNotes.length > 0) {
      form
        .validateFields()
        .then((values) => {
          const updatedPackage = {
            ...values,
            dayNotes: values.package_daynote,
          };

          if (currentPackage) {
            setPackages(
              packages.map((pack) =>
                pack.key === currentPackage.key
                  ? { ...updatedPackage, key: currentPackage.key }
                  : pack
              )
            );
          } else {
            const newPackage = {
              ...updatedPackage,
              key: Date.now().toString(),
            };
            setPackages([...packages, newPackage]);
          }

          setIsModalVisible(false);
          resetStates();
        })
        .catch(() => {
          toast.warning("Fill all reaquired fields");
        });
    } else {
      toast.warning("Add Day notes");
    }
  };

  return (
    <div
      style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}
      className="shadow-md"
    >
      <List
        dataSource={packages}
        grid={{ column: 1 }}
        renderItem={(item: any) => (
          <List.Item
            key={item.key}
            actions={[
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showPackageModal(item)}
              />,
              <Button
                type="link"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleRemovePackage(item.key)}
              />,
            ]}
          >
            <div className="flex flex-col">
              <List.Item.Meta
                title={item.package_title}
                description={`Price: ${item.package_price}`}
              />
              <div
                className="w-50"
                dangerouslySetInnerHTML={{
                  __html: item.package_additionalNote,
                }}
              />
            </div>
          </List.Item>
        )}
      />

      {packages && packages.length < 3 ? (
        <Button
          type="dashed"
          className="shadow-lg py-5 my-3"
          onClick={() => showPackageModal(null)}
          style={{ width: "100%" }}
        >
          <PlusOutlined /> Add Package
        </Button>
      ) : (
        <p className="pb-0 mb-0 mt-3 text-center">
          You have reached the maximum package limit (Max : 3).
        </p>
      )}

      <Modal
        title={currentPackage ? "Edit Package" : "Add Package"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
          resetStates();
        }}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="package_title"
            label="Package Title"
            rules={[
              { required: true, message: "Please enter the package title" },
            ]}
          >
            <Input placeholder="Enter package title" />
          </Form.Item>

          <Form.Item
            name="package_price"
            label="Package Price"
            rules={[
              { required: true, message: "Please enter the package price" },
            ]}
          >
            <Input placeholder="Enter package price" />
          </Form.Item>

          <Form.List name="package_daynote">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    direction="horizontal"
                    className="flex items-center"
                  >
                    <div className="flex items-start py-0 my-0">
                      <Form.Item
                        {...restField}
                        name={[name, "dayNote"]}
                        fieldKey={[fieldKey, "dayNote"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the day note",
                          },
                        ]}
                      >
                        <Input placeholder={`Day ${name + 1}`} />
                      </Form.Item>
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(name)}
                        danger
                      />
                    </div>
                  </Space>
                ))}
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add Day Note
                </Button>
              </>
            )}
          </Form.List>

          <Form.Item
            name="package_additionalNote"
            label="Additional Note"
            rules={[
              {
                required: true,
                message: "Please add some note for package details",
              },
            ]}
          >
            <ReactQuill
              onChange={(value) =>
                form.setFieldsValue({ package_additionalNote: value })
              }
              placeholder="Tell us about the package"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPackageForm;

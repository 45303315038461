import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const AddGuestBioData = async (formData: any) => {
  try {
    const formDataSet = new FormData();
    // Append other form data
    formDataSet.append(
      "inputs",
      JSON.stringify({
        ...formData,
      })
    );

    // Append the profile image if it exists
    if (formData?.profileImage?.file) {
      formDataSet.append(
        "profileImageArray[]",
        formData.profileImage.file.originFileObj
      );
    }

    const response = await axios.post(
      `${SERVER_API}/Guest/add-guest-bio-data.php`,
      formDataSet,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
    // return response.data;
  } catch (error: any) {
    console.error("Error uploading guide bio data:", error);
    toast.error("Something went wrong");
  }
};

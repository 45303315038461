import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetAllGalleryImage = async () => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Other/get-all-gallery-image.php`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

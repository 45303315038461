import React, { useState } from "react";
import { Card, Button, Checkbox, Result, Tag } from "antd";
import { toast } from "react-toastify";
import {
  FIRSTPAYMENT,
  IBookingStatus,
  IPaymentType,
  UserRolesEnum,
} from "../../../src/Constant";
import { getBookingStatusColor } from "../../../src/lib";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { usePaymentModal } from "../../../src/Hook/usePaymentModal";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AddBookingStatus } from "../../../src/Api";
import AddReviewModal from "../Add-review-model/AddReviewModal";

const RequestCard = ({ title, children, statusColor, statusText }) => (
  <Card title={title} className="mb-4" bordered={true}>
    <div className="flex justify-between items-center">
      {children}
      <Tag color={statusColor}>{statusText}</Tag>
    </div>
  </Card>
);

export const PaymentDetails = ({
  bookingData,
  selectedMessageId,
  fetchData,
}) => {
  const { proceedToPay } = usePaymentModal();
  const { user } = useKindeAuth();
  const user_role = sessionStorage.getItem("user_role");
  const [agreed, setAgreed] = useState(false);
  const [isPaymentModelVisible, setIsPaymentModelVisible] =
    useState<boolean>(false);

  const isGuest = user_role === UserRolesEnum.guest;
  const title = isGuest ? "Contact Your Guide" : "Contact Your Guest";
  const contactName = isGuest
    ? bookingData?.guideData?.name
    : bookingData?.guestData?.name;
  const contactPhone = isGuest
    ? bookingData?.guideData?.contactNumber
    : bookingData?.guestData?.contactNumber;
  const contactEmail = isGuest
    ? bookingData?.guideData?.email
    : bookingData?.guestData?.email;

  const handleCheckboxChange = (e) => {
    setAgreed(e.target.checked);
  };

  const handlePayment = () => {
    if (agreed) {
      handleOnlineOrder();
    } else {
      toast.warning(
        "Please agree to the terms and conditions before proceeding."
      );
    }
  };

  const paymentAmount = (
    bookingData?.finalamount *
    (FIRSTPAYMENT / 100)
  ).toFixed(2);
  const statusColor = getBookingStatusColor(
    bookingData?.status_array[2] || IBookingStatus.firstPaymentPending
  );
  const statusText =
    bookingData?.status_array[2] || IBookingStatus.firstPaymentPending;

  const handleOnlineOrder = async () => {
    try {
      const data = {
        bookingid: bookingData?.bookingid,
        paymentAmount: paymentAmount,
        guestId: user?.id,
        selectedMessageId: selectedMessageId,
        fetchData: fetchData,
      };
      await proceedToPay(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefundRequest = async () => {
    try {
      const data = await AddBookingStatus({
        status: isGuest
          ? IBookingStatus.refundRequested
          : IBookingStatus.refundGuideRequested,
        selectedMessageId: selectedMessageId,
      });

      if (data?.isDataAdded) {
        fetchData();
        toast.success("Refund reques successfully send");
      } else {
        toast.warning("Somthing went wrong.please contact our support team");
      }
    } catch {
      toast.error("Sothing went wrong!");
    }
  };

  const handleTourCompleteRequest = async () => {
    try {
      const data = await AddBookingStatus({
        status: IBookingStatus.tourCompleted,
        selectedMessageId: selectedMessageId,
      });

      if (data?.isDataAdded) {
        fetchData();
        toast.success("Tour completed successfully");
      } else {
        toast.warning("Somthing went wrong.please contact our support team");
      }
    } catch {
      toast.error("Sothing went wrong!");
    }
  };

  return (
    <>
      {bookingData?.status_array[1] === IBookingStatus.accepted && (
        <>
          <RequestCard
            title="Payment Details"
            statusColor={statusColor}
            statusText={statusText}
          >
            <h2>Advance payment</h2>
            <h2 className="mr-2">${paymentAmount}</h2>
          </RequestCard>

          {isGuest && (
            <>
              {!bookingData?.status_array[2] && (
                <>
                  <Checkbox
                    className="mt-3"
                    checked={agreed}
                    onChange={handleCheckboxChange}
                  >
                    I have read and agree to the{" "}
                    <a href="#">terms and conditions</a>.
                  </Checkbox>
                  <Button
                    type="primary"
                    className="hover:cursor-pointer"
                    block
                    onClick={handlePayment}
                    disabled={!agreed}
                  >
                    Proceed to Payment
                  </Button>
                </>
              )}

              {bookingData?.status_array[2] ===
                IBookingStatus.firstPaymentDone && (
                <>
                  <p className="text-end underline text-blue-700 cursor-pointer">
                    Download your receipt
                  </p>
                </>
              )}
            </>
          )}

          {bookingData?.status_array[2] === IBookingStatus.firstPaymentDone && (
            <>
              <div className="flex flex-col items-center justify-center p-6 bg-lime-100 rounded-lg shadow-md mt-4">
                <Result
                  status="success"
                  title="Advanced Payment is Completed"
                  subTitle="Thank you for using our service! We hope you had a great experience."
                />
              </div>
              <Card
                title={title}
                className="shadow-lg rounded-lg bg-lime-50  mt-4"
              >
                <div className="flex items-center space-x-4">
                  <UserOutlined className="text-2xl text-blue-500" />
                  <div>
                    <h2 className="text-xl font-semibold">{contactName}</h2>
                  </div>
                </div>
                <div className="mt-4 flex flex-col space-y-2">
                  <div className="flex items-center space-x-2">
                    <PhoneOutlined className="text-lg text-green-500" />
                    <span className="text-lg">{contactPhone}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <MailOutlined className="text-lg text-red-500" />
                    <span className="text-lg">{contactEmail}</span>
                  </div>
                </div>
                <div className="mt-4 flex justify-between">
                  <Button
                    type="primary"
                    icon={<PhoneOutlined />}
                    className="bg-blue-500 hover:bg-blue-600 text-white"
                    href={`tel:${contactPhone}`}
                  >
                    Call Now
                  </Button>
                  <Button
                    type="default"
                    icon={<MailOutlined />}
                    className="text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                    href={`mailto:${contactEmail}`}
                  >
                    Send Email
                  </Button>
                </div>
              </Card>
            </>
          )}

          {bookingData?.status_array[2] === IBookingStatus.firstPaymentDone &&
            !bookingData?.status_array[3] &&
            user_role !== UserRolesEnum.admin && (
              <div className="mt-4">
                <Button type="primary" onClick={handleRefundRequest} block>
                  Submit Refund Request
                </Button>
                {!isGuest && (
                  <Button
                    className="mt-5 shadow-lg"
                    type="default"
                    onClick={handleTourCompleteRequest}
                    block
                  >
                    Tour complete
                  </Button>
                )}
              </div>
            )}

          <>
            {bookingData?.status_array[3] &&
              (bookingData?.status_array[3] ===
                IBookingStatus.refundRequested ||
                bookingData?.status_array[3] ===
                  IBookingStatus.refundGuideRequested) && (
                <div className="flex flex-col items-center justify-center p-6 bg-blue-100 rounded-lg shadow-md mt-4">
                  <Result
                    status="info"
                    title={`${
                      bookingData?.status_array[3] ===
                      IBookingStatus.refundRequested
                        ? "Guest"
                        : "Guide"
                    } Refund Requested`}
                    subTitle="Your tour request has been received. Please wait for further updates."
                  />
                  <p className="text-center">
                    <i>Contact us via email or WhatsApp</i>
                  </p>
                </div>
              )}
          </>

          <>
            {bookingData?.status_array[3] &&
              bookingData?.status_array[3] === IBookingStatus.tourCompleted && (
                <>
                  <div className="flex flex-col items-center justify-center p-6 bg-green-100 rounded-lg shadow-md mt-4">
                    <Result
                      status="success"
                      title="Tour Completed"
                      subTitle={
                        isGuest
                          ? "Thank you for using our service! We hope you had a memorable journey."
                          : "Your guest's tour is complete! We hope they had a great experience."
                      }
                    />
                    {isGuest && (
                      <>
                        {bookingData?.status_array[4] &&
                        bookingData?.status_array[4] ===
                          IBookingStatus.reviewadded ? (
                          <>
                            <p className="my-0 py-0">Review Added</p>
                          </>
                        ) : (
                          <Button
                            type="primary"
                            className="bg-blue-500 hover:bg-blue-600 text-white"
                            onClick={() => {
                              setIsPaymentModelVisible(true);
                            }}
                          >
                            Add Review for your guide
                          </Button>
                        )}

                        {/* Review modal */}
                        <AddReviewModal
                          fetchData={fetchData}
                          guideId={bookingData?.guideData?.id}
                          guestId={bookingData?.guestData?.id}
                          selectedMessageId={selectedMessageId}
                          visible={isPaymentModelVisible}
                          setIsPaymentModelVisible={setIsPaymentModelVisible}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
          </>

          <>
            {bookingData?.status_array[4] ===
              IBookingStatus.refundCompleted && (
              <div className="flex flex-col items-center justify-center p-6 bg-green-100 rounded-lg shadow-md mt-4">
                <Result
                  status="success"
                  title="Refund Completed"
                  subTitle={
                    isGuest
                      ? "Your refund has been processed successfully. We hope to serve you again."
                      : "The refund for your guest has been completed."
                  }
                />
                <>
                  {isGuest && (
                    <p className="text-end underline text-blue-700 cursor-pointer">
                      Download your receipt
                    </p>
                  )}
                </>
              </div>
            )}
          </>

          <>
            {bookingData?.status_array[4] === IBookingStatus.refundRejected && (
              <div className="flex flex-col items-center justify-center p-6 bg-red-100 rounded-lg shadow-md mt-4">
                <Result
                  status="error"
                  title="Refund Rejected"
                  subTitle={
                    isGuest
                      ? "Unfortunately, your refund request was rejected. Please contact support for further assistance."
                      : "Your guest's refund request was rejected. Please review the details."
                  }
                />
              </div>
            )}
          </>
        </>
      )}
    </>
  );
};

import { getBookingStatusColor } from "../../../src/lib";
import { MessageOutlined } from "@ant-design/icons";
import { Empty, Spin, Tag, Tooltip } from "antd";
import React from "react";
import { TruncatedTextWithTooltip } from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";
import { toast } from "react-toastify";

export const InboxList = ({
  isInboxMsgListLoading,
  inboxMsgList,
  selectedMessageId,
  handleSelectMessage,
  showBookingDrawer,
  showInboxDrawer,
}: any) => {
  return (
    <>
      <div className="mx-0 px-0 h-[100vh]">
        {isInboxMsgListLoading ? (
          <>
            <div className="flex justify-center items-center h-[80vh]">
              <Spin />
              <p className="my-0 py-0 ms-3">Data loading</p>
            </div>
          </>
        ) : (
          <>
            {inboxMsgList && inboxMsgList?.length === 0 ? (
              <div className="text-center py-20 h-[80vh] text-gray-500">
                <Empty description="No messages" />
              </div>
            ) : (
              inboxMsgList &&
              inboxMsgList?.map((message) => (
                <div
                  key={message.id}
                  className={`flex items-center px-3 py-5 border-b last:border-b-0 hover:bg-gray-100 cursor-pointer relative ${
                    selectedMessageId === message.id ? "bg-blue-100" : ""
                  }`}
                  onClick={() => {
                    handleSelectMessage(message.id);
                    showBookingDrawer();
                  }}
                >
                  <div className="flex flex-col w-full">
                    <div className="font-semibold text-lg flex mt-3">
                      <span>BID: {message.bookingid}</span>
                      <span className="uppercase ms-5">
                        (
                        {TruncatedTextWithTooltip(
                          message.name ? message.name : "No name",
                          10
                        )}
                        )
                      </span>
                    </div>
                    <div className="flex justify-between items-center my-1">
                      <p className="my-0 py-0 text-gray-500">
                        Booking date: {message.datetime}
                      </p>

                      <>
                        {selectedMessageId === message.id && (
                          <Tooltip title="Open Inbox" placement="top">
                            <MessageOutlined
                              className="text-white bg-blue-600 hover:bg-blue-700 p-2 rounded-full absolute bottom-1 right-2 text-xl shadow-lg transition-transform transform hover:scale-105 cursor-pointer"
                              onClick={(e) => {
                                if (selectedMessageId === message.id) {
                                  e.stopPropagation();
                                  showInboxDrawer();
                                } else {
                                  toast.info("Select booking first", {
                                    position: "top-center",
                                  });
                                }
                              }}
                            />
                          </Tooltip>
                        )}
                      </>
                    </div>
                  </div>
                  <Tag
                    color={getBookingStatusColor(message.status)}
                    className="absolute top-1 mx-0 right-0"
                  >
                    {message.status}
                  </Tag>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Input, Button, Avatar, List, Empty } from "antd";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { GetMessage, SendMessage } from "../../../src/Api";
import { getCurrentTime } from "../../../src/lib";
import { IBookingStatus, MSG_REFETCHING_SECONDS } from "../../../src/Constant";

export const Inbox = ({ selectedMessageId, isDrawerOpen }) => {
  const user_role = sessionStorage.getItem("user_role");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [status, setStatus] = useState<any>(IBookingStatus.requested);

  const fetchChat = async () => {
    if (!isDrawerOpen) return;

    const data = await GetMessage(selectedMessageId);
    if (data && data?.isDataSet) {
      setMessages(data.chat);
      setStatus(data?.status);
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      fetchChat();
      const intervalId = setInterval(() => {
        fetchChat();
      }, MSG_REFETCHING_SECONDS);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isDrawerOpen, selectedMessageId]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const chatdata = {
        sender: user_role,
        text: newMessage,
        datetime: getCurrentTime(),
      };

      setMessages([...messages, chatdata]);
      //send msg
      await SendMessage(selectedMessageId, chatdata);
      setNewMessage("");
    }
  };

  return (
    <div className="flex flex-col h-[85vh]">
      <div className="flex-1 p-0 m-0 overflow-y-auto bg-white rounded-md">
        <List
          className="message-list "
          itemLayout="vertical"
          dataSource={messages}
          renderItem={(message) => (
            <List.Item
              className={`flex ${
                message.sender === user_role ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`flex items-center p-3 rounded-lg ${
                  message.sender === user_role
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                <Avatar
                  className={`mr-2 ${
                    message.sender === user_role ? "ml-2" : ""
                  }`}
                  size={40}
                  icon={
                    message.sender === user_role ? (
                      <SendOutlined
                        style={{
                          transform: "rotate(-40deg)",
                        }}
                      />
                    ) : (
                      <UserOutlined />
                    )
                  }
                />
                <div>
                  <strong>
                    {message.sender == user_role ? "You" : message.sender}
                  </strong>
                  <p className="my-1">{message.text}</p>
                </div>
              </div>
              <span className="mx-3 flex justify-end">
                <small>
                  <i>{message.datetime}</i>
                </small>
              </span>
            </List.Item>
          )}
        />
      </div>
      <div className="flex items-center mt-4">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          placeholder="Type a message"
          className="flex-1 mr-2"
          size="large"
        />
        <Button
          type="primary"
          disabled={
            status == IBookingStatus.cancelled ||
            status == IBookingStatus.rejected 
          }
          icon={
            <SendOutlined
              style={{
                transform: "rotate(-40deg)",
              }}
            />
          }
          onClick={handleSendMessage}
        ></Button>
      </div>
      <div>
        <small>
          <i>
            <b> Important Notice:</b> Do not share personal details or contact
            information in the chat. Sharing such information is against our
            privacy policy. We are not responsible for any consequences
            resulting from the disclosure of personal or contact details in the
            chat. Please communicate responsibly.
          </i>
        </small>
      </div>
    </div>
  );
};

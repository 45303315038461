import { UserOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

export const ProfileIcon = ({showDrawer}) => {
  return (
    <>
      <Tooltip placement="right" title={"Profile"}>
        <Button
          type="primary"
          shape="circle"
          size="large"
          style={{ padding: "1.6rem" }}
          className="shadow-lg mx-0 md:mx-5 fixed top-[22%] md:top-[12%] z-[999]"
          icon={<UserOutlined style={{ fontSize: "1.6rem" }} />}
          onClick={showDrawer}
        />
      </Tooltip>
    </>
  );
};

import { toast } from "react-toastify";
import axios from "axios";

export const GetAllCountryData = async () => {
  try {
    const response = await axios.get("https://restcountries.com/v3.1/all");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetMessage = async (bookingId) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/chat/get-msg.php?bookingId=${bookingId}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};

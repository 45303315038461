import React, { useEffect } from "react";
import { Form, Select, Row, Col } from "antd";
import { languageLevels, languages } from "../../../src/Constant";

const { Option } = Select;

const LanguageForm = ({ languageOptions, setLanguageOptions }) => {
  const [form] = Form.useForm();
  
  // Set the initial values for languages in the form
  useEffect(() => {
    form.setFieldsValue({
      languages: languageOptions.map((option) => option.language),
    });
  }, [languageOptions, form]);

  const handleLanguageChange = (value) => {
    // Preserve existing proficiency levels
    const newLanguageOptions = [...languageOptions];
    const selectedLanguages = value.map((lang) => {
      const existingOption = newLanguageOptions.find(
        (option) => option.language === lang
      );
      return existingOption || { language: lang, level: null };
    });

    setLanguageOptions(selectedLanguages);
  };

  const handleLevelChange = (index, level) => {
    const updatedOptions = [...languageOptions];
    updatedOptions[index].level = level;
    setLanguageOptions(updatedOptions);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{...languageOptions}}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Languages"
            name="languages"
            rules={[
              {
                required: true,
                message: "Please select or type your languages",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select or type your languages"
              style={{ width: "100%" }}
              onChange={handleLanguageChange}
              value={languageOptions.map((option) => option.language)}
            >
              {languages.map((lang) => (
                <Option key={lang.value} value={lang.value}>
                  {lang.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12} className="my-0 py-0">
          {languageOptions.map((option, index) => (
            <Form.Item
              key={index}
              label={`${
                languages.find((lang) => lang.value === option.language)
                  ?.label || option.language
              } Proficiency`}
            >
              <Select
                value={option.level}
                onChange={(level) => handleLevelChange(index, level)}
                placeholder="Select proficiency level"
                style={{ width: "100%" }}
              >
                {languageLevels.map((level) => (
                  <Option key={level} value={level}>
                    {level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ))}
        </Col>
      </Row>
    </Form>
  );
};

export default LanguageForm;

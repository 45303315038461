import React, { useState } from "react";
import { Card, Button, Result, Tag, Input, Popconfirm } from "antd";

import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { FIRSTPAYMENT, IBookingStatus } from "../../../../src/Constant";
import { getBookingStatusColor } from "../../../../src/lib";
import { AddBookingStatus } from "../../../../src/Api";
import { toast } from "react-toastify";

const RequestCard = ({ title, children, statusColor, statusText }) => (
  <Card title={title} className="mb-4" bordered={true}>
    <div className="flex justify-between items-center">
      {children}
      <Tag color={statusColor}>{statusText}</Tag>
    </div>
  </Card>
);

export const PaymentDetails = ({
  bookingData,
  selectedMessageId,
  fetchData,
}) => {
  const [guestRefundAmount, setGuestRefundAmount] = useState<any>("");
  const [guideRefundAmount, setGuideRefundAmount] = useState<any>("");
  const paymentAmount = (
    bookingData?.finalamount *
    (FIRSTPAYMENT / 100)
  ).toFixed(2);
  const statusColor = getBookingStatusColor(
    bookingData?.status_array[2] || IBookingStatus.firstPaymentPending
  );
  const statusText =
    bookingData?.status_array[2] || IBookingStatus.firstPaymentPending;

  const handleRefundReject = async () => {
    try {
      const data = await AddBookingStatus({
        status: IBookingStatus.refundRejected,
        selectedMessageId: selectedMessageId,
      });

      if (data?.isDataAdded) {
        fetchData();
        toast.success("Refund Request rejecting successfully");
      } else {
        toast.warning("Somthing went wrong.please contact our support team");
      }
    } catch {
      toast.error("Sothing went wrong!");
    }
  };

  const handleRefundApprove = async () => {
    if (guestRefundAmount !== "" && guideRefundAmount !== "") {
      try {
        const data = await AddBookingStatus({
          status: IBookingStatus.refundCompleted,
          selectedMessageId: selectedMessageId,
          guestRefundAmount: guestRefundAmount,
          guideRefundAmount: guideRefundAmount,
          orderid: bookingData?.bookingid,
        });

        if (data?.isDataAdded) {
          fetchData();
          toast.success("Refund claim completed");
        } else {
          toast.warning("Somthing went wrong.please contact our support team");
        }
      } catch {
        toast.error("Sothing went wrong!");
      }
    } else {
      toast.warning("Please enter both refund amounts before approving.");
    }
  };
  return (
    <>
      <RequestCard
        title="Payment Details"
        statusColor={statusColor}
        statusText={statusText}
      >
        <h2>Advance payment</h2>
        <h2 className="mr-2">${paymentAmount}</h2>
      </RequestCard>

      <Card title={"GUIDE"} className="shadow-lg rounded-lg bg-lime-50  mt-4">
        <div className="flex items-center space-x-4">
          <UserOutlined className="text-2xl text-blue-500" />
          <div>
            <h2 className="text-xl font-semibold">
              {bookingData?.guideData?.name}
            </h2>
          </div>
        </div>
        <div className="mt-4 flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <PhoneOutlined className="text-lg text-green-500" />
            <span className="text-lg">
              {bookingData?.guideData?.contactNumber}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <MailOutlined className="text-lg text-red-500" />
            <span className="text-lg">{bookingData?.guideData?.email}</span>
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            type="primary"
            icon={<PhoneOutlined />}
            className="bg-blue-500 hover:bg-blue-600 text-white"
            href={`tel:${bookingData?.guideData?.contactNumber}`}
          >
            Call Now
          </Button>
          <Button
            type="default"
            icon={<MailOutlined />}
            className="text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
            href={`mailto:${bookingData?.guideData?.email}`}
          >
            Send Email
          </Button>
        </div>
      </Card>

      <Card title={"GUEST"} className="shadow-lg rounded-lg bg-lime-50  mt-4">
        <div className="flex items-center space-x-4">
          <UserOutlined className="text-2xl text-blue-500" />
          <div>
            <h2 className="text-xl font-semibold">
              {bookingData?.guestData?.name}
            </h2>
          </div>
        </div>
        <div className="mt-4 flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <PhoneOutlined className="text-lg text-green-500" />
            <span className="text-lg">
              {bookingData?.guestData?.contactNumber}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <MailOutlined className="text-lg text-red-500" />
            <span className="text-lg">{bookingData?.guestData?.email}</span>
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            type="primary"
            icon={<PhoneOutlined />}
            className="bg-blue-500 hover:bg-blue-600 text-white"
            href={`tel:${bookingData?.guestData?.contactNumber}`}
          >
            Call Now
          </Button>
          <Button
            type="default"
            icon={<MailOutlined />}
            className="text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
            href={`mailto:${bookingData?.guestData?.email}`}
          >
            Send Email
          </Button>
        </div>
      </Card>

      <>
        <>
          {bookingData?.status_array[2] === IBookingStatus.firstPaymentDone && (
            <>
              <div className="flex flex-col items-center justify-center p-6 bg-lime-100 rounded-lg shadow-md mt-4">
                <Result
                  status="success"
                  title="Advanced Payment is Completed"
                  subTitle="Thank you for using our service! We hope you had a great experience."
                />
              </div>
            </>
          )}
        </>

        <>
          {bookingData?.status_array[3] &&
            (bookingData?.status_array[3] === IBookingStatus.refundRequested ||
              bookingData?.status_array[3] ===
                IBookingStatus.refundGuideRequested) && (
              <div className="flex flex-col items-center justify-center p-6 bg-blue-100 rounded-lg shadow-md mt-4">
                <Result
                  status="info"
                  title={`${
                    bookingData?.status_array[3] ===
                    IBookingStatus.refundRequested
                      ? "Guest"
                      : "Guide"
                  } Refund Requested`}
                  subTitle="A refund request has been received. Please review the details and take action accordingly."
                />

                {!bookingData?.status_array[4] && (
                  <div className="flex flex-col items-center space-y-6">
                    <div className="flex flex-row items-center">
                      <Input
                        title="Guest Refund Amount"
                        id="guestRefund"
                        type="number"
                        placeholder="Guest"
                        value={guestRefundAmount}
                        onChange={(e) => setGuestRefundAmount(e.target.value)}
                        required
                      />
                      <Input
                        id="guideRefund"
                        title="Guide Refund Amount"
                        type="number"
                        placeholder="Guide"
                        value={guideRefundAmount}
                        onChange={(e) => setGuideRefundAmount(e.target.value)}
                        required
                      />
                    </div>

                    <div className="flex space-x-4">
                      <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => {
                          handleRefundReject();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" className="px-10 py-4" danger>
                          Reject
                        </Button>
                      </Popconfirm>

                      <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => {
                          handleRefundApprove();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="px-10 py-4" type="primary">
                          Approve
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                )}
              </div>
            )}
        </>

        <>
          {bookingData?.status_array[3] &&
            bookingData?.status_array[3] === IBookingStatus.tourCompleted && (
              <>
                <div className="flex flex-col items-center justify-center p-6 bg-green-100 rounded-lg shadow-md mt-4">
                  <Result
                    status="success"
                    title="Tour Completed"
                    subTitle={"Tour is completed successfully"}
                  />
                </div>
              </>
            )}
        </>

        <>
          {bookingData?.status_array[4] === IBookingStatus.refundCompleted && (
            <div className="flex flex-col items-center justify-center p-6 bg-green-100 rounded-lg shadow-md mt-4">
              <Result
                status="success"
                title="Refund Completed"
                subTitle={"The refund has been completed."}
              />
            </div>
          )}
        </>

        <>
          {bookingData?.status_array[4] === IBookingStatus.refundRejected && (
            <div className="flex flex-col items-center justify-center p-6 bg-red-100 rounded-lg shadow-md mt-4">
              <Result
                status="error"
                title="Refund Rejected"
                subTitle={"Refund request was rejected."}
              />
            </div>
          )}
        </>
      </>
    </>
  );
};

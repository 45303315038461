import React from "react";
import { Typography, Divider, Space } from "antd";

const { Title, Paragraph } = Typography;

export const WebTermsAndCondition = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <Title level={1}>
          Terms and Conditions for Tour Guide Booking System
        </Title>
        <Title level={3} className="mt-2">
          Need to understand
        </Title>
      </div>

      <Paragraph className="mb-8">
        Welcome to our Tour Guide Booking System! We offer personalized and
        memorable tour experiences tailored to your interests and needs. Our
        professional guides ensure a safe, enjoyable, and insightful journey,
        providing expert knowledge and assistance throughout your trip. Whether
        you're exploring local attractions, seeking adventure, or learning about
        new cultures, we are here to make your travel experience seamless and
        unforgettable. Book with us today for a hassle-free and enriching
        adventure!
      </Paragraph>

      <Divider orientation="left">1. Booking and Payment</Divider>
      <Paragraph className="mb-6">
        <ul className="list-disc list-inside ml-4">
          <li>20% advance payment is required to confirm a booking.</li>
          <li>
            The remaining balance must be paid as follows:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>30% at the start of the tour.</li>
              <li>50% at the end of the tour.</li>
            </ul>
          </li>
          <li>
            All advance payments must be made through our secure payment
            gateway. Other balances can be paid directly to the guide.
          </li>
        </ul>
      </Paragraph>

      <Divider orientation="left">2. Cancellation and Refund Policy</Divider>
      <Paragraph className="mb-6">
        <ul className="list-disc list-inside ml-4">
          <li>
            Guests can cancel their bookings up to 15 days before the tour for
            an 80% refund of the advance payment.
          </li>
          <li>
            Cancellations made between 15 and 5 days before the tour will
            receive a 40% refund of the advance payment.
          </li>
          <li>
            Cancellations made within 5 days of the tour will not be eligible
            for a refund.
          </li>
          <li>
            If the guide is unable to continue the tour due to unforeseen
            circumstances, an alternative guide will be provided under the same
            terms.
          </li>
          <li>
            If you need any refund under the condition, please contact our team.
          </li>
        </ul>
      </Paragraph>

      <Divider orientation="left">3. Tour Customization</Divider>
      <Paragraph className="mb-6">
        <ul className="list-disc list-inside ml-4">
          <li>
            If guests have specific plans or requirements, they must communicate
            with the guide through our chat service before booking to agree on
            the price.
          </li>
          <li>
            Any unmentioned plans or requirements discussed at the start of the
            tour will need to be negotiated with the guide.
          </li>
        </ul>
      </Paragraph>

      <Divider orientation="left">4. Safety and Security</Divider>
      <Paragraph className="mb-6">
        <ul className="list-disc list-inside ml-4">
          <li>
            The guide will provide security for the guest's vehicle and luggage.
          </li>
          <li>Guests must stay with the guide at all times during the tour.</li>
        </ul>
      </Paragraph>

      <Divider orientation="left">5. Daily Planning</Divider>
      <Paragraph className="mb-6">
        <ul className="list-disc list-inside ml-4">
          <li>
            Guests and guides must discuss the tour's plan either each morning
            or the evening before.
          </li>
          <li>
            Guests are responsible for additional entrance fees at various
            sites.
          </li>
          <li>
            All accommodation, food, and other expenses are the guest's
            responsibility, but the guide will assist in making arrangements.
          </li>
        </ul>
      </Paragraph>

      <Divider orientation="left">6. Final Amount</Divider>
      <Paragraph className="mb-6">
        The final amount, including taxes, will be provided upfront. No
        additional charges will be imposed unless additional requirements are
        discussed with the guide.
      </Paragraph>

      <Divider orientation="left">7. Special Needs</Divider>
      <Paragraph className="mb-6">
        Guests with special needs (e.g., mobility issues or medical conditions)
        must inform the guide beforehand.
      </Paragraph>

      <Divider orientation="left">8. Privacy</Divider>
      <Paragraph className="mb-6">
        No personal data will be shared before the booking is confirmed. We are
        not responsible for any issues arising from data shared post-booking.
      </Paragraph>

      <Divider orientation="left">9. Safety Advice</Divider>
      <Paragraph className="mb-6">
        Permission and advice should be sought for unsafe places and activities.
      </Paragraph>

      <Divider orientation="left">10. Prohibited Activities</Divider>
      <Paragraph className="mb-6">
        Unauthorized activities will result in account suspension without
        refund.
        <br />A chauffeur guide can accommodate up to seven guests. However, we
        do not take responsibility for issues related to guest count.
      </Paragraph>

      <Divider orientation="left">11. Guide's Vehicle</Divider>
      <Paragraph className="mb-6">
        Guides' vehicles must be well-maintained, safe, and equipped with air
        conditioning and other necessary facilities.
      </Paragraph>

      <Divider orientation="left">12. Lost and Found</Divider>
      <Paragraph className="mb-6">
        Guides will manage all lost and found items. It's advisable to inform us
        and contact the guest to return the items.
      </Paragraph>
    </div>
  );
};

import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../../src/Constant";

export const AddBookingData = async (formData: any) => {
  try {
    const formDataSet = new FormData();
    // Append other form data
    formDataSet.append("inputs", JSON.stringify(formData));

    const response = await axios.post(
      `${SERVER_API}/Booking/add-booking-data.php`,
      formDataSet,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    toast.error("Something went wrong");
  }
};

import React, { useState } from "react";
import { Upload, Modal, List, Image, Button, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/es/upload/interface";
import { IMAGE_URL } from "../../../src/Constant";

const GalleryImageForm = ({ images, setImages }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>(
    images.map((img: any, index: any) => ({
      uid: img.uid || index.toString(),
      name: img.name,
      status: "done",
      url: `${IMAGE_URL}/upload/${img.name}`,
    }))
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setImages(newFileList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    return isJpgOrPng;
  };

  const handleRemove = (file) => {
    const updatedList = fileList.filter((img) => img.uid !== file.uid);
    setFileList(updatedList);
    setImages(updatedList);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
        maxCount={9}
        accept="image/*"
        multiple={true}
      >
        {fileList.length >= 9 ? null : uploadButton}
      </Upload>

      <Modal
        visible={previewVisible}
        title="Image Preview"
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default GalleryImageForm;

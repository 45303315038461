import { toast } from "react-toastify";
import {
  AddBookingStatus,
  generateHashValue,
  GetGuestBioDataForPayment,
} from "../Api";
import {
  CURRENCY,
  DASHBOARD,
  IBookingStatus,
  IPaymentType,
  UserRolesEnum,
  WEB_LINK,
} from "../Constant";

export const usePaymentModal = () => {
  const proceedToPay = async ({
    bookingid,
    paymentAmount,
    guestId,
    selectedMessageId,
    fetchData,
  }) => {
    try {
      //Get hash code
      const code = await generateHashValue({
        orderId: bookingid,
        totalAmount: paymentAmount,
      });

      //Get user personal data
      const guestData = await GetGuestBioDataForPayment(guestId);
      const guestPersonalData = guestData?.userBioData;

      if (
        !guestPersonalData ||
        !guestPersonalData.name ||
        !guestPersonalData.email
      ) {
        toast.error(
          "Please fill in your personal information before proceeding."
        );
        return;
      }

      const payment = {
        sandbox: true,
        //@ts-ignore
        merchant_id: 1223312,
        return_url: `${WEB_LINK}/${UserRolesEnum.guest}/${DASHBOARD}/${guestId}`,
        cancel_url: `${WEB_LINK}/${UserRolesEnum.guest}/${DASHBOARD}/${guestId}`,
        notify_url: `${WEB_LINK}/${UserRolesEnum.guest}/${DASHBOARD}/${guestId}`,
        order_id: bookingid,
        items: "TOUR BOOKING",
        amount: paymentAmount,
        //@ts-ignore
        currency: CURRENCY,
        first_name: guestPersonalData.name,
        last_name: guestPersonalData.name,
        email: guestPersonalData.email,
        phone: guestPersonalData.contactNumber,
        address: guestPersonalData.personalAddress,
        country: guestPersonalData.country,
        city: guestPersonalData.country,
        hash: code.hash,
      };

      window.payhere.onCompleted = async function onCompleted(orderId: any) {
        try {
          const data = await AddBookingStatus({
            status: IBookingStatus.firstPaymentDone,
            selectedMessageId: selectedMessageId,
            paymenttype: IPaymentType.booking,
            paymentAmount: paymentAmount,
            orderid: bookingid,
          });

          if (data?.isDataAdded) {
            fetchData();
            toast.success(
              "Your payment is successfully"
            );
          } else {
            toast.warning(
              "Somthing went wrong.please contact our support team"
            );
          }
        } catch {
          toast.warning("Somthing went wrong.please contact our support team");
        }
      };

      window.payhere.onDismissed = function onDismissed() {
        toast.info("Payment was dismissed.");
      };

      window.payhere.onError = function onError(error: any) {
        toast.error("Something went wrong with the payment. Please try again.");
      };

      window.payhere.startPayment(payment);
    } catch (error) {
      toast.error(
        "There was an error processing your payment. Please try again later."
      );
    }
  };

  return {
    proceedToPay,
  };
};

import React from "react";

export const GuideTermsAndCond = () => {
  return (
    <>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, voluptas
      temporibus aspernatur accusantium numquam ratione nisi a aliquid
      necessitatibus cum, optio recusandae esse molestiae rem repellendus
      explicabo ut, accusamus quibusdam!
    </>
  );
};

import { IBookingStatus } from "../../../src/Constant";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

export const BookingStatusSteps = ({ statusArray }) => {
    
  const statusMap = {
    [IBookingStatus.requested]: {
      title: "Requested",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.accepted]: {
      title: "Accepted",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.cancelled]: {
      title: "Cancelled",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
    },
    [IBookingStatus.rejected]: {
      title: "Rejected",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
    },
    [IBookingStatus.firstPaymentPending]: {
      title: "First Payment Pending",
      icon: <CheckCircleOutlined style={{ color: "orange" }} />,
    },
    [IBookingStatus.firstPaymentDone]: {
      title: "First Payment Done",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.secondPaymentDone]: {
      title: "Second Payment Done",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.tourCompleted]: {
      title: "Tour Completed",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.reviewadded]: {
      title: "Review Added",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.refundRequested]: {
      title: "Guest Refund Requested",
      icon: <CheckCircleOutlined style={{ color: "blue" }} />,
    },
    [IBookingStatus.refundGuideRequested]: {
      title: "Guide Refund Requested",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.refundCompleted]: {
      title: "Refund Completed",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    },
    [IBookingStatus.refundRejected]: {
      title: "Refund Rejected",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
    },
  };

  return (
    <div className="p-4 flex flex-col space-y-4">
      {statusArray.map((status, index) => (
        <div key={index} className="flex items-center space-x-3">
          {statusMap[status]?.icon}
          <span className="text-lg font-medium">
            {statusMap[status]?.title}
          </span>
        </div>
      ))}
    </div>
  );
};
